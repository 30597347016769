import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from 'src/app/shared.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SECA';
  constructor(private translate: TranslateService,
    private _sharedService: SharedService) {
    translate.setDefaultLang('es');
    this._sharedService.messageSource.next('es');
}


useLanguage(language: string) {
  this.translate.use(language);
}

}

export class DataSubscriber{
  Name:String;
  lastName:String;
  Phone:String;
  Company:String;
  Position:String;
  Email:String;
  RegisterDate: String

  setNAME(Name:String){
    this.Name=Name;
  }

  getNAME(){
    return this.Name;
  }

  setLastNAME(LastName:String){
    this.lastName=LastName;
  }

  getLastNAME(){
    return this.lastName;
  }

  setPHONE(Phone:String){
    this.Phone=Phone;
  }

  getPHONE(){
    return this.Phone;
  }

  setCOMPANY(Company:String){
    this.Company=Company;
  }

  getCOMPANY(){
    return this.Company;
  }

  setPOSITION(Position:String){
    this.Position=Position;

  }

  getPOSITION(){
    return this.Position;
  }

  setEMAIL(Email:String){
    this.Email=Email;
  }

  getEMAIL(){
    return this.Email;
  }

  setREGISTERDATE(RegisterDate:String){
    this.RegisterDate=RegisterDate;

  }

  getREGISTERDATE(){
    return this.RegisterDate;
  }

}


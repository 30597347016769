import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from '../app/login/login.component';
import { RestoreComponent } from './login/restore/restore.component';
import { PasswordComponent } from './login/password/password.component';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  { path: '', pathMatch:  'full', redirectTo:  'login'},
  { path: 'dashboard/:id', component: DashboardComponent, loadChildren: () => import(`./dashboard/dashboard.module`).then(m => m.DashboardModule) },
  { path:'login', component:  LoginComponent},
  { path:'login/restore', component:  RestoreComponent},
  { path:'password', component:  PasswordComponent},];
 
@NgModule({
   imports: [CommonModule,RouterModule.forRoot(routes)],
   exports:[RouterModule]
})
export class AppRoutingModule { }

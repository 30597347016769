import { Component, OnInit,ViewChild,Injectable } from '@angular/core';
import {NgbDateStruct,NgbDatepickerI18n, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { BsDatepickerConfig, } from 'ngx-bootstrap/datepicker';

const I18N_VALUES = {
  'en': {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  }

  // other languages you would support
};


@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})

export class CalendarComponent {

    model: NgbDateStruct;
    date: {year: number, month: number};
    bsInlineValue = new Date();
    bsInlineRangeValue: Date[];
    maxDate = new Date();
    isenable:boolean;
    bsConfig: Partial<BsDatepickerConfig>= new BsDatepickerConfig();;
    colorTheme = 'theme-default';
    @ViewChild('dp',{static:true}) dp:any;

    constructor() {
      this.isenable=true;

      /* this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate]; */
      /* this.applyTheme(this.dp); */
      this.selectToday();
    }

    selectToday() {
      this.model = {year: this.bsInlineValue.getFullYear(), month: this.bsInlineValue.getMonth() + 1, day: this.bsInlineValue.getDate()};
    }

    /* applyTheme(pop: any) {
      // create new object on each property change
      // so Angular can catch object reference change
      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, showWeekNumbers: false, adaptivePosition: true, isAnimated: true  });
      setTimeout(() => {
        //pop.show();
      });
    } */

    
  }






import { Component, OnInit } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-button-palette-top',
  templateUrl: './button-palette-top.component.html',
  styleUrls: ['./button-palette-top.component.css']
})
export class ButtonPaletteTopComponent implements OnInit {

  constructor(private translate: TranslateService,
    private _sharedService:SharedService) {

      translate.setDefaultLang('es');

    }

  ngOnInit() {
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this._sharedService.messageSource.next(language);
    //this.makenavigator(language)
  }

}

import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router,ActivatedRoute, } from '@angular/router';
import { EventEmitterService } from '../event-emitter.service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserInterface } from 'src/app/models/user-interface';
import {SharedService} from 'src/app/shared.service'

import { map } from 'rxjs/operators';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  state$: Observable<object>;
  wastranslate:boolean;
  dataDashboard:boolean;
  user: UserInterface;
  constructor(private router:ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private routerone:Router,
    private translate: TranslateService,
    private authService: AuthService,
    private _sharedService: SharedService) {
      translate.setDefaultLang('es');
    // this.router.url.subscribe(data=>{
    //   this.showdata(data)
    //   console.log("extras dashboard  "+data)})

    //this.home.makenavigator('en')

  }

  // showdata(data){
  //   console.log("extras dashboard 2  "+data)
  //   if(data=='dashboard'){
  //     this.wastranslate=true;
  //   }else{
  //     this.wastranslate=false;
  //   }
  //   this.dataDashboard=this.wastranslate;
  //   console.log("extras dashboard 3  "+this.dataDashboard)

  // }



  ngOnInit() {
    console.log('hola')
    this.user= this.authService.getCurrentUser();
  }
  firstComponentFunction(){
    this.eventEmitterService.onFirstComponentButtonClick();
  }

}

export class UserCreate{
  Name:String;
  Email:String;
  Phone:String;
  Username:String;


  setName(nombre:String){
    this.Name=nombre;

  }

  getName(){
    return this.Name;
  }

  setEmail(email:String){
    this.Email=email;

  }

  getEmail(){
    return this.Email;
  }

  setPhone(phone:String){
    this.Phone=phone;

  }

  getPhone(){
    return this.Phone;
  }

  setUsername(username:String){
    this.Username=username;

  }

  getUsername(){
    return this.Username;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError, retry } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import * as sha512 from 'js-sha512';

import { UserInterface } from "../models/user-interface";
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class AuthService {

  constructor(private http: HttpClient) { }
  headers : HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  registerUser(){

  }

  loginUser(account: string, passphrase: string): Observable<any>{
    //const url_api = "http://seca-api.exiresoft.com.ve/user/login"
    //const url_api = "http://fosterukke-001-site1.etempurl.com/user/login"
    const url_api = "http://api.appseca.website//user/login"
    //const url_api = "http://api-seca.exiresoft.com.ve/user/login"
    return this.http.post<UserInterface>(
      url_api,
      {account, passphrase},
      {headers: this.headers})
      .pipe(map(data =>data));
  }

  SetUser(user: UserInterface): void {
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", user_string);
  }

  setToken(token): void {
    localStorage.setItem("accessToken", token);
  }

  getToken(){
    return localStorage.getItem("accessToken");
  }

  getCurrentUser(): UserInterface{
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  logoutUser(){

 }

  restoreService(email: string): Observable<any> {
    console.log("entre al servicio")
     //const url_api = "http://seca-api.exiresoft.com.ve/user/request"
     //const url_api = "http://fosterukke-001-site1.etempurl.com/user/request"
    // const url_api = "http://seca.mujerindependiente.blog/user/request"
    const url_api = "http://api-seca.exiresoft.com.ve/user/request"
      return this.http.post(url_api,{email: email}).pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

    updatePassword(obj:any):Observable<any>{
      console.log(obj)
      const url_api = "http://api-seca.exiresoft.com.ve/user/recovery"
      return this.http.put(url_api,obj).pipe(
        retry(2),
        catchError(this.handleError)
        );
    }
    
    handleError(error: HttpErrorResponse){
      alert("Correo No registrado en el sistema")
      return throwError(error);
    }

}


import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { DataCompany } from 'src/app/models/data-company-interface';
import { Alert } from 'selenium-webdriver';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {DataApiService} from 'src/app/services/data-api.service';
import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-lawyer-update',
  templateUrl: './lawyer-update.component.html',
  styleUrls: ['./lawyer-update.component.css']
})
export class LawyerUpdateComponent implements OnInit {

  newLawyerValidations: FormGroup;
  verify:boolean;
  result:String;
  nameRif:string;
  phone: string;
  firstName: string;
  lastName: string;
  //document: string;
  //resumeData:DataCompany=new DataCompany();
  totalData:DataCompany[];
  myArrStr:string;
  myArrStr1:string;
  searchKey: string;
  lawyerId:string;
  newCompanyValidations: FormGroup;



  constructor(public dialogRef: MatDialogRef<LawyerUpdateComponent>,
    private translate: TranslateService,private _sharedService:SharedService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DataUpdateLawyerModel
    ,private as:DataApiService) {
      translate.setDefaultLang('es');
      this.lawyerId=data.id;
      console.log("Id of lawyer is: "+this.lawyerId);
      if(this.lawyerId!=''){
        this.getDetailOfLawyer(this.lawyerId);
      }else{
        alert("No se pudo ver el Id");
      }
     }

  ngOnInit() {
    this.newLawyerValidations = this.formBuilder.group({
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      firstName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      lastName: ['', [Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      //document: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      //Address: ['', [Validators.required,Validators.minLength(4),]],
    });
  }

  getDetailOfLawyer(index){


    this.as.getUserDetailData(index,1).subscribe(data=>{
      console.log("data "+data)
      this.myArrStr= JSON.stringify(data);
      this.myArrStr1= JSON.stringify(data.content);

      console.log("telefono"+this.myArrStr)
      console.log(data.content[0].first_name)
      this.phone=data.content[0].phone;
      this.firstName=data.content[0].first_name;
      this.lastName=data.content[0].last_name;
      //this.document=data.content[0].person_id;


    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )
    

  }

  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.phone='';
    this.firstName='';
    this.lastName='';
    //this.document='';
  }


  onSummit(){

    if(typeof this.phone !== 'undefined'
    &&typeof this.firstName !== 'undefined'
    &&typeof this.lastName !== 'undefined'
    //&&typeof this.document !== 'undefined'
    ){
    
    console.log("por aca se imprime "+this.phone);
    console.log("por aca se imprime "+this.firstName);
    console.log("por aca se imprime "+this.lastName);
    //console.log("por aca se imprime "+this.document);
    this.upadateDataLawyer(this.phone,this.firstName,this.lastName,/* this.document , */ this.lawyerId);  

   
    }else{
      this.onClose();
    }
  }

  upadateDataLawyer(phone,firstName,lastName,/* document, */index){
   
    var dataLawyer = 
      {
        "phone": phone,
	      "first_name": firstName,
        "last_name": lastName,
        //"person_id":document,   
      };
    //var jsonData = JSON.parse(data);
    console.log("Upadate obj "+dataLawyer);
    this.as.updateProfileData(dataLawyer,index).subscribe(response=>{

       console.log("response "+response);
       this.myArrStr= JSON.stringify(response.status);
       console.log("response "+this.myArrStr);        
       let myObj = JSON.parse(this.myArrStr);
       console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 && myObj.message==="Ok!"){
         this.onClose();
       } error=>{
         console.log("Error delete case"+error)
         this.handleError(error);
       }
    });

  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getNumber(obj){
    console.log(obj);
    this.phone = obj;
  }

}

export class DataUpdateLawyerModel {

  constructor(public id: string) {
  }
}

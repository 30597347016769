import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from 'src/app/shared.service';
import { AuthService } from '../services/auth.service';
import { UserInterface } from 'src/app/models/user-interface';

declare var jQuery;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  user: UserInterface;
  currentUser: any;
  showNewsletter: boolean;

  constructor(private translate: TranslateService,
     private _sharedService:SharedService,
     private authService: AuthService) {
      translate.setDefaultLang('es');



  }

  useLanguage(language: string) {
    this.translate.use(language);
    this._sharedService.messageSource.next(language);
    //console.log("extras side menu "+language)
  }

  ngOnInit() {

    // MetisMenu
    //console.log("Activacion side-menu")
    jQuery('#side-menu').metisMenu();
    this.user= this.authService.getCurrentUser();
    this.currentUser= this.user.privilegies;
    console.log (this.currentUser)
  }

}

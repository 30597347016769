import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-create',
  templateUrl: './calendar-create.component.html',
  styleUrls: ['./calendar-create.component.css']
})
export class CalendarCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

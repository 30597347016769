import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataApiService } from 'src/app/services/data-api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {SharedService} from 'src/app/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse} from '@angular/common/http';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {

  newLawyerValidations: FormGroup;
  phone: string;
  firstName: string;
  lastName: string;
  userId:string;
  myArrStr: string;

  constructor(public dialogRef: MatDialogRef<UserUpdateComponent>,
    private formBuilder: FormBuilder,private as:DataApiService,
     _sharedService:SharedService,private translate: TranslateService,@Inject(MAT_DIALOG_DATA) public data: DataUpdateUserModel) {
      translate.setDefaultLang('es');

       this.userId=data.id;
      console.log("Id of user is: "+this.userId);
      if(this.userId!=''){
        this.getDetailOfUser(this.userId);
      }else{
        alert("No se pudo ver el Id");
      }
      }

  ngOnInit() {
    this.newLawyerValidations = this.formBuilder.group({
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      firstName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      lastName: ['', [Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      //document: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      //Address: ['', [Validators.required,Validators.minLength(4),]],
    });
  }

  getDetailOfUser(index){
    this.as.getUserDetailData(index,1).subscribe(data=>{
      console.log("data "+data)
      //this.myArrStr= JSON.stringify(data);
      //this.myArrStr1= JSON.stringify(data.content);

      //console.log("telefono"+this.myArrStr)
      console.log(data.content[0].first_name)
      this.phone=data.content[0].phone;
      this.firstName=data.content[0].first_name;
      this.lastName=data.content[0].last_name;
      //this.document=data.content[0].person_id;


    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )
  }

  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.phone='';
    this.firstName='';
    this.lastName='';
    //this.document='';
  }


  onSummit(){

    if(typeof this.phone !== 'undefined'
    &&typeof this.firstName !== 'undefined'
    &&typeof this.lastName !== 'undefined'){

    console.log("por aca se imprime "+this.phone);
    console.log("por aca se imprime "+this.firstName);
    console.log("por aca se imprime "+this.lastName);
    //console.log("por aca se imprime "+this.document);
    this.updateDataUser(this.phone,this.firstName,this.lastName, this.userId);
    }else{
      this.onClose();
    }
  }

  updateDataUser(phone,firstName,lastName,index){

    var dataUser =
      {
        "phone": phone,
	      "first_name": firstName,
        "last_name": lastName
      };
    //var jsonData = JSON.parse(data);
    console.log("Upadate obj "+dataUser);
    this.as.updateProfileData(dataUser,index).subscribe(response=>{

       console.log("response "+response);
       this.myArrStr= JSON.stringify(response.status);
       //console.log("response "+this.myArrStr);
       let myObj = JSON.parse(this.myArrStr);
       //console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 ){
         this.onClose();
       } error=>{
         console.log("Error delete case"+error)
         this.handleError(error);
       }
    });
  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    //this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.phone = obj;
  }

}

export class DataUpdateUserModel {

  constructor(public id: string) {
  }

}

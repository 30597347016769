import { Component, OnInit, EventEmitter,Output,Inject,ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatOption,MatDialog, MatSelect } from '@angular/material';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { DataCompany } from 'src/app/models/data-company-interface';
import { Alert } from 'selenium-webdriver';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {DataApiService} from 'src/app/services/data-api.service';
import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataPerson, DataPersonContent } from 'src/app/models/data-person-class';
import {DataContacAddedDetail,DataContacAddedDetailContent} from 'src/app/models/data-contac-added';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-company-update',
  templateUrl: './company-update.component.html',
  styleUrls: ['./company-update.component.css']
})
export class CompanyUpdateComponent implements OnInit {

  verify:boolean;
  result:String;
  resultInsert:String;
  nameRif:string;
  idCompany:string;
  idCompanyFromUser:string;
  Rif:String;
  CompanyName:String;
  TradeName:String;
  Phone:string;
  Address:String;
  @ViewChild('allSelected',{static:false}) private allSelected: MatOption;
  @ViewChild('mySelec',{static:false}) private mySelec:any;

  //resumeData:DataCompany=new DataCompany();
  totalData:DataCompany[];
  myArrStr:string;
  myArrStr1:string;

  @Output() childEvent = new EventEmitter();
  searchKey: string;
  companyId:string;
  newCompanyValidations: FormGroup;
  toppings = new FormControl();
  contacs:Array<string>;
  contacsAdded:Array<string>=[];
  contacsNameAdded:Array<string>=[];

  DataContacs:DataPersonContent[];
  DataContacsAdded:DataContacAddedDetailContent[];
  needAddContac:boolean;

  constructor(public dialogRef: MatDialogRef<CompanyUpdateComponent>,
    private translate: TranslateService,private _sharedService:SharedService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DataUpdateCompanyModel
    ,private as:DataApiService,
    public dialog: MatDialog) {
      translate.setDefaultLang('es');
      this.companyId=data.id;
      console.log("Id of company is: "+this.companyId);
      if(typeof this.companyId !== 'undefined' && this.companyId!==''){
        localStorage.setItem("companyId", this.companyId);
        console.log("Save comoany id")
      }else{
        //this.router.navigate(['/dashboard/id:/casescenter/'])
        this.companyId=localStorage.getItem("companyId");
        console.log("Recover case id" +this.companyId)
      }


     }

  ngOnInit() {
    this.newCompanyValidations = this.formBuilder.group({
      rif: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      company: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      tradeName: ['', [Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      Address: ['', [Validators.required,Validators.minLength(4),]],
    });

    this.getContactData();
    if(this.companyId!=''){
      this.getDetailOfCompany(this.companyId);
    }else{
      alert("No se pudo ver el Id");
    }


  }

  getContactAdded(clientId,iden){

    this.as.getContacsAdded(clientId).subscribe(data=>{
       console.log("data "+data)

      if(data.status.code==200 && data.status.message=="Ok!"){

        if(data.content.length>0){
          this.myArrStr1= JSON.stringify(data.content);
          console.log("size contac data "+data.content.length)
          this.DataContacsAdded=data.content;
          if(iden==2){
            console.log("reset arrays ")
            this.contacsAdded=[];
            this.contacsNameAdded=[];
          }
          for(var i = 0, len = data.content.length; i < len; i++)
        {
          this.contacsAdded.push(data.content[i].person_id)
          this.contacsNameAdded.push(data.content[i].contact_full_name)
          console.log("ids contacs added "+data.content[i].person_id)
        }
          console.log("Conatacts added "+this.contacsAdded.length)
          console.log("Conatacts name added "+this.contacsNameAdded.length)

        }else{
          alert("Este cliente no tiene contactos registrados")
          this.needAddContac=true;
        }


    }else{
      alert("falla del sistema SECA, intente nuevamente");
    }
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )
  }

  getContactData(){
    this.as.getPersonData().subscribe(data=>{
      // console.log("data "+data)

      if(data.status.code==200 && data.status.message=="Ok!"){
      this.DataContacs=data.content;
    }else{
      alert("falla del sistema SECA, intente nuevamente");
    }
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )

  }

  getIdComapnyfromUserService(companyId){
    this.as.getcomanyIdfromUserService(companyId).subscribe(data=>{
      console.log("data "+data)
      this.myArrStr= JSON.stringify(data);
      this.myArrStr1= JSON.stringify(data.content);

      if(data.status.code==200 && data.status.message=="Ok!"){
        //console.log("JSON RESPONSE "+this.myArrStr)
        if(data.content.length>0){
          this.idCompanyFromUser=data.content[0].id;
          console.log("Id the Company from user "+this.idCompanyFromUser)
          this.getContactAdded(this.idCompanyFromUser,1);
        }else{
          console.log("company not avaiable ")
        }


      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }

      // console.log("JSON  "+this.myArrStr1)
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )


  }

  getDetailOfCompany(index){
    console.log("Company id "+index)
    this.as.getCompanyDetailData(index).subscribe(data=>{
      console.log("data "+data)
      this.myArrStr= JSON.stringify(data);
      this.myArrStr1= JSON.stringify(data.content);

      if(data.status.code==200 && data.status.message=="Ok!"){
        //console.log("JSON RESPONSE "+this.myArrStr)
        this.idCompany=data.content[0].id;
        console.log("Id the Company "+this.idCompany)
        this.getIdComapnyfromUserService(this.idCompany);
        this.Rif=data.content[0].business_id;
        this.CompanyName=data.content[0].business_name;
        this.TradeName=data.content[0].trade_name;
        this.Phone=data.content[0].phone
        this.Address=data.content[0].address;
      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }

      // console.log("JSON  "+this.myArrStr1)
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )


  }

  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.Rif='';
    this.CompanyName='';
    this.TradeName='';
    this.Address='';
  }

  getContacs(name,lastname,index){
    console.log("La seleccion del contacto id es "+name+" "+lastname+" "+index)
    console.log("contactos "+this.contacs)

    for(var i = 0, len = this.contacsAdded.length; i < len; i++)
        {

          //console.log("contactos add 2 "+this.contacsAdded[i])
          if(this.contacsAdded[i]==index){
            console.log("este contacto ya esta asociado al cliente "
            +this.contacsNameAdded[i])
            // alert("Este contacto "+this.contacsNameAdded[i]+" ya esta asociado al cliente "
            // +this.CompanyName+" deseas borrarlo ? ")
            this.confirmDialog("Este contacto "+this.contacsNameAdded[i]+" ya esta asociado al cliente "
             +this.CompanyName+" deseas borrarlo ?",this.contacsAdded[i]);
            console.log("id company "+this.idCompanyFromUser)

            this.needAddContac=false;
            console.log("no need add contac new "+this.needAddContac)
            //this.ngOnInit();
            break;
          }else{

            this.needAddContac=true;
            console.log(" need add contac new "+this.needAddContac)
          }

        }

        console.log(" result add contact  "+this.needAddContac)
        if(this.needAddContac){
        //add cointact to client
        console.log("Este contacto "+name+" "+lastname+" no esta asociado al cliente "
        +this.CompanyName+" deseas asociarlo ?")
        this.confirmDialogInsert("Este contacto "+name+" "+lastname+" no esta asociado al cliente "
        +this.CompanyName+" deseas asociarlo ?",index)
        }


  }

  unselect(): void {
    this.contacs = undefined;
    console.log("contactos unselec "+this.contacs)
 }


  onSummit(){

    if(typeof this.Rif !== 'undefined'
    &&typeof this.CompanyName !== 'undefined'
    &&typeof this.TradeName !== 'undefined'
    &&typeof this.Address !== 'undefined'
    &&typeof this.Phone !== 'undefined' ){

    console.log("por aca se imprime "+this.Rif);
    console.log("por aca se imprime "+this.CompanyName);
    console.log("por aca se imprime "+this.TradeName);
    console.log("por aca se imprime "+this.Address);
    this.upadateDataCompany(this.Rif,this.CompanyName,this.TradeName,this.Address,
      this.companyId,this.Phone);


    }else{
      this.onClose();
    }
  }

  upadateDataCompany(rif,companyname,tradename,address,index,phone){

    var dataCompany =
      {
        "business_id": rif,
	      "business_name": companyname,
	      "trade_name": tradename,
        "address": address,
        "phone": phone

      };
    //var jsonData = JSON.parse(data);
    console.log("Upadate obj "+dataCompany);
    this.as.updateCompanyItem(dataCompany,index).subscribe(response=>{
      console.log("response "+response);
      this.myArrStr= JSON.stringify(response.status);
      console.log("response "+this.myArrStr);
      let myObj = JSON.parse(this.myArrStr);
      console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 && myObj.message==="Ok!"){
        console.log("Referece  "+response.reference);
        this.onClose();
      } error=>{
        console.log("Error delete case"+error)
        this.handleError(error);
      }
    });

  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  confirmDialog(messagedata,contactWho): void {
    const message = messagedata;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      console.log("resultado "+this.result);
      if(this.result){
      //this.deleteItem(index);
      setTimeout(() => {
        //this.allSelected.deselect();
        this.unselect();
        console.log("disselct selection ");
        console.log("conatc who "+contactWho);
        console.log("client who "+this.idCompanyFromUser);
        this.removeContacToClient(this.idCompanyFromUser,contactWho);
        // this.mySelec.close();
        // this.getContactAdded(this.idCompanyFromUser,2);


      }, 500);

    }else{
      this.unselect();
    }

    });
  }

  confirmDialogInsert(messagedata,contactWho): void {
    const message = messagedata;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRefInsert = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefInsert.afterClosed().subscribe(dialogResultIns => {
      this.resultInsert = dialogResultIns;
      console.log("resultado "+this.resultInsert);
      if(this.resultInsert){
      //this.deleteItem(index);
      setTimeout(() => {
        console.log("Add new contact ");
        console.log("conatc who "+contactWho);
        console.log("client who "+this.idCompanyFromUser);
        this.unselect();
        this.addContacToClient(this.idCompanyFromUser,contactWho);
        // this.mySelec.close();
        // this.getContactAdded(this.idCompanyFromUser,2);

      }, 500);

    }else{
      this.unselect();
      console.log("do nothing add contact")
      //this.unselect();
    }

    });
  }

  addContacToClient(clientId,personId){
    this.as.AddContacsToClient(clientId,personId)
          .subscribe(response=>{
              console.log("data contact set "+response);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data contac set "+this.myArrStr);
                this.mySelec.close();
                this.getContactAdded(this.idCompanyFromUser,2);

              }else{
                alert("falla del sistema SECA, intente nuevamente");
                this.mySelec.close();
              }

          },error=>{

            this.handleError(error);
          }

          )

  }

  removeContacToClient(clientId,personId){
    this.as.RemoveContacsToClient(clientId,personId)
          .subscribe(response=>{
              console.log("data contact set "+response);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data contac set "+this.myArrStr);
                this.mySelec.close();
                this.getContactAdded(this.idCompanyFromUser,2);

              }else{
                alert("falla del sistema SECA, intente nuevamente");
                this.mySelec.close();
              }

          },error=>{

            this.handleError(error);
          }

          )

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.Phone = obj;
  }

}
export class DataUpdateCompanyModel {

  constructor(public id: string) {
  }
}

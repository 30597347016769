import { Component, OnInit } from '@angular/core';
import {MatDialogRef,MatDialog} from "@angular/material";
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';

import { Alert } from 'selenium-webdriver';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';

import {UserCreate} from 'src/app/models/user-create-class'
import { AuthService } from 'src/app/services/auth.service';
import { UserInterface } from 'src/app/models/user-interface';
import { DataApiService } from 'src/app/services/data-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  breakpoint: number;
  breakpointbig: number;
  verify:boolean;
  result:string;
  nameRif:string;
  name:string;
  email:string;
  phone:string;
  userName:string;
  lastName: string
  document: string;
  type: string;
  currentUser : UserInterface;
  currentToken: string;
  resumeData:UserCreate=new UserCreate();
  // totalData:DataCompany[];
/*   name = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('', [Validators.required, Validators.email]);
  username = new FormControl('', [Validators.required, Validators.email]); */

  searchKey: string;
  indexCount:number=0;
  newUserValidations: FormGroup;
  newUser: string;

  constructor(public dialogRef: MatDialogRef<UserCreateComponent>,
    public dialog: MatDialog,
private translate: TranslateService,private _sharedService:SharedService,
    private formBuilder: FormBuilder,
    private dataApi: DataApiService,
    private as: AuthService) {
      translate.setDefaultLang('es');
      this.currentUser=this.as.getCurrentUser();
      this.currentToken=this.currentUser.token;
    }

  ngOnInit() {

    this.newUserValidations = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      lastname: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      document: ['', [Validators.required,Validators.minLength(8), Validators.maxLength(15), Validators.pattern('[0-9-" "]+')]],
      email: ['', [Validators.required,Validators.minLength(4),Validators.email]],
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      username: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
    });
    this.breakpoint = (window.innerWidth <= 620) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint init "+this.breakpoint+" "+window.innerWidth)
    console.log("breakpointbig init "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '60%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('48%','65%');
    }

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 850) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint "+this.breakpoint)
    console.log("breakpointbig "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '60%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('48%','65%');
    }else{
      this.dialogRef.updateSize('35%','60%');
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UserCreateComponent, {
       width: '25%'

    });
  }

 /*  getErrorMessage() {

    this.result=this.name.hasError('required') ? 'You must enter a value' :
            '';
    return this.result;
  }

  getErrorMessageNombre() {

    this.result=this.email.hasError('required') ? 'You must enter a value' :
            '';
    return this.result;
  }

  getErrorMessageNombreC() {

    this.result=this.phone.hasError('required') ? 'You must enter a value' :
            '';
    return this.result;
  }

  getErrorMessageDireccion() {

    this.result=this.username.hasError('required') ? 'You must enter a value' :
            '';
    return this.result;
  }*/

  onClose() {

    this.dialogRef.close();
  } 

  onClean(){
    this.name='';
    this.lastName;
    this.email='';
    this.phone='';
    this.userName='';
    this.document='';
  }


  onSummit(){

    console.log("por aca se imprime "+this.name);
    console.log("por aca se imprime "+this.lastName);
    console.log("por aca se imprime "+this.email);
    console.log("por aca se imprime "+this.phone);
    console.log("por aca se imprime "+this.userName);
    console.log("por aca se imprime "+this.document);
    if(typeof this.name !== 'undefined'
    &&typeof this.lastName !== 'undefined'   
    &&typeof this.email !== 'undefined'
    &&typeof this.phone !== 'undefined'
    &&typeof this.userName !== 'undefined'
    &&typeof this.document !== 'undefined' ){
      this.type = '1a1c72bd-002d-4257-8b9f-bfc8d713ab73'

      console.log("ultima impresion "+this.type)
      var dataUser =
      {
        "person":{
          "email": this.email,
          "person_id": this.document,
          "first_name": this.name,
          "last_name": this.lastName,
          "phone": this.phone
        },
        "account": this.userName, 
        "role_id": this.type,
        "token": this.currentToken
      };

      this.dataApi.setNewUsers(dataUser).subscribe(response=>{
        //console.log("response "+response);
        if(response.status.code==200 && response.status.message=="Ok!"){
          this.newUser = response.content.id
          console.log(this.newUser)
          //this.onClose();
        }else{
          alert("falla del sistema, revise sus campos e inetente nuevamente")
        }
         //error=>{
          //console.log("Error delete case"+error)
          //this.handleError(error);
        //}
      this.onClose();
      });

    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.phone = obj;
  }

}

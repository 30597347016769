import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from 'src/app/shared.service';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
    private translate: TranslateService,
    private _sharedService:SharedService) {
    // Update view with given values
    translate.setDefaultLang('es');
    this.title = data.title;
    this.message = data.message;

    //  this._sharedService.messageSource.subscribe((message:string)=>{

    //     console.log("Mensaje de confrimation "+message);
    //     if(message!=''){
    //       this.useLanguage(message);
    //     }else{
    //       this.useLanguage('en');
    //     }

    //   });
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  makenavigator(language){
    console.log("confrimation 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  ngOnInit() {
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}




export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}

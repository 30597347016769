import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserInterface } from "../../models/user-interface";

import { MatDialog } from '@angular/material'
import { PasswordComponent } from '../../login/password/password.component';
import { throwError } from 'rxjs';
import { ConfirmModalModel, ConfirmModalComponent } from 'src/app/login/confirm-modal/confirm-modal.component';


@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent implements OnInit {

  public email: string;
  public isError = false;
  message: any;

  constructor(private authService: AuthService,  public dialog: MatDialog,) { }

  ngOnInit() {
  }

  restorePass() {
/*     console.log(this.email)
    return this.authService.restoreService(this.email)
    .subscribe(data =>{
      console.log(data)
      console.log(data.status.code)
      this.confirmDialog(data.status.code)
    },
    error =>  {this.handleError(error)}
    );   */
    console.log(this.email)
    return this.authService.restoreService(this.email).subscribe(response =>{
      console.log("response "+response);
      if(response.status.code==200){
        const message = 'Por favor revise su bandeja de correo pronto deberá llegarle un correo';
        this.confirmDialog(message)
      }else{
        console.log('entre en el else')
        alert("falla del sistema, revise sus campos e intente nuevamente")
        const message = 'Ha ocurrido un error por favor intente más tarde';
        this.confirmDialog(message)
      } error=>{
        console.log('entre en el error')
        console.log("Error delete case"+error)
        this.handleError(error);
      }
    });

  };

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  confirmDialog(message): void {
    const dialogData = new ConfirmModalModel("Confirm Action", message,2);

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }

}

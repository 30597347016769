import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {DataApiService} from 'src/app/services/data-api.service';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams,HttpEventType } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  @Component({
  selector: 'app-user-edit-profile',
  templateUrl: './user-edit-profile.component.html',
  styleUrls: ['./user-edit-profile.component.css']
})
export class UserEditProfileComponent implements OnInit {

  userId:string;
  updateProfileValidations: FormGroup;
  profileName:string;
  profileLastName:string;
  profilePhone:string;
  //profilePass:string;
  myArrStr:string;
  myArrStr1:string;
  idReference:string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: DataUpdateProfileModel,
  private translate: TranslateService,
  private _sharedService:SharedService,
  private formBuilder: FormBuilder,
  public dialogRef: MatDialogRef<UserEditProfileComponent>,
  private as:DataApiService) {
    translate.setDefaultLang('es');
    this.userId=data.id;
    console.log("Id User is: "+this.userId);

   }

   makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }



  ngOnInit() {

    this.updateProfileValidations = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20), Validators.pattern('[a-zA-Z0-9-" "/,.]+')]],
      lastname: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30), Validators.pattern('[a-zA-Z0-9-" "/,.]+')]],
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      // password: ['', [Validators.required,Validators.minLength(4)]],
    });
    this.getUserDetailData(this.userId);

  }

  getUserDetailData(userId){

    this.as.getUserDetailData(userId,2).subscribe(data=>{

      if(data.status.code=="200" && data.status.message=="Ok!"){
        if(data.content[0].status_name.includes("Disponible")){
          console.log ("data reference"+data.content[0].reference)
          // console.log ("lastName"+data.content.last_name)
          // this.profileName=data.content[0].full_name;
          // //this.profileLastName=data.content.last_name;
          // if(data.content[0].phone!=''){
          //   this.profilePhone=data.content[0].phone;
          // }else{
          //   this.profilePhone="No tiene número registrado en el sistema"
          // }

          // console.log ("phone"+data.content[0].phone)
          this.idReference=data.content[0].reference;
          this.getPersonDetail(this.idReference);


        }else{
          alert("esta persona no esta disponible")
        }



      }

    },error=>{
      this.handleError(error);
    }
    )

  }

  getPersonDetail(id){

    this.as.getPersonDetailData(id).subscribe(resp=>{

      if(resp.status.code=="200" && resp.status.message=="Ok!"){
          this.profileName=resp.content[0].first_name;
          this.profileLastName=resp.content[0].last_name;
          if(resp.content[0].phone!=''){
            this.profilePhone=resp.content[0].phone;
          }else{
            this.profilePhone="No tiene número registrado en el sistema"
          }

      }

    },error=>{
      this.handleError(error)
    }

    )


  }

  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.profileName='';
    this.profileLastName='';
    this.profilePhone='';

  }

  onSummit(){

    if(typeof this.profileName !== 'undefined'
    &&typeof this.profileLastName !== 'undefined'
    &&typeof this.profilePhone !== 'undefined'
    &&typeof this.idReference !== 'undefined'){

    console.log("on summit "+this.profileName);
    console.log("on summit "+this.profileLastName);
    console.log("on summit "+this.profilePhone);
    console.log("on summit "+this.idReference);

    this.upadateDataProfile(this.profileName,
      this.profileLastName,this.profilePhone,this.idReference);

   this.onClose();
    }else{
      this.onClose();
    }
  }

  upadateDataProfile(name,lastname,phone,reference){

    var dataProfile =
      {

	    "first_name": name,
      "last_name": lastname,
      "phone": phone

      };
    //var jsonData = JSON.parse(data);
    console.log("Upadate obj "+dataProfile);
    this.as.updateProfileData(dataProfile,reference).subscribe(response=>{

      console.log("response "+response);
      this.myArrStr= JSON.stringify(response.status);
      console.log("response "+this.myArrStr);
      let myObj = JSON.parse(this.myArrStr);
      console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 && myObj.message==="Ok!"){
        console.log("update reference profile  "+response.reference);
        this.onClose();
      } error=>{
        console.log("Error update profile"+error)
        this.onClose();
        this.handleError(error);
      }
    });

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.profilePhone = obj;
  }

}

export class DataUpdateProfileModel {

  constructor(public id: string) {
  }
}

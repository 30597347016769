import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormControl, Validators, FormGroup,FormBuilder} from '@angular/forms';
import { DataSubscriber } from 'src/app/models/data-subscriber-interface';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-subscriber-create',
  templateUrl: './subscriber-create.component.html',
  styleUrls: ['./subscriber-create.component.css']
})
export class SubscriberCreateComponent implements OnInit {
  name:String;
  lastName:String;
  phone:String;
  company:String;
  position:String;
  email:String;
  registerDate: String

  resumeData:DataSubscriber=new DataSubscriber();

  newSubscriberValidations: FormGroup;

  constructor(public dialogRef: MatDialogRef<SubscriberCreateComponent>,
              private formBuilder: FormBuilder,
              private _sharedService:SharedService,
              private translate: TranslateService ) {
              translate.setDefaultLang('es');
              this.registerDate="02/11/19";

               }

  ngOnInit() {
    this.newSubscriberValidations = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      lastname: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      company: ['', [ Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9/,.]+')]],
      position: ['', [ Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9/,.]+')]],
      email: ['', [Validators.required,Validators.minLength(4),Validators.email]],
    });
  }

  onSummit(){

    if(typeof this.name !== 'undefined'
    &&typeof this.phone !== 'undefined'
    &&typeof this.company !== 'undefined'
    &&typeof this.position !== 'undefined'
    &&typeof this.email !== 'undefined'
    &&typeof this.registerDate !== 'undefined'){
    // this.resumeData.Rif=this.Rif;
    // this.resumeData.CompanyName=this.CompanyName;
    // this.resumeData.TradeName=this.TradeName;
    // this.resumeData.Address=this.Address;
    // console.log("por aca se imprime "+this.name);
    // console.log("por aca se imprime "+this.phone);
    // console.log("por aca se imprime "+this.company);
    // console.log("por aca se imprime "+this.position);
    // console.log("por aca se imprime "+this.email);
    this.resumeData.setNAME(this.name+" "+this.lastName);
    this.resumeData.setLastNAME(this.lastName);
    this.resumeData.setPHONE(this.phone);
    this.resumeData.setCOMPANY(this.company);
    this.resumeData.setPOSITION(this.position);
    this.resumeData.setEMAIL(this.email);
    this.resumeData.setREGISTERDATE(this.registerDate);
    this._sharedService.setDataSubscriber(this.resumeData);

   this.onClose();
    }else{
      this.onClose();
    }
  }


  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.name='';
    this.phone='';
    this.company='';
    this.position='';
    this.email='';

  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.phone = obj;
  }
}

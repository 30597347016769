import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscriber } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { DataCompany } from 'src/app/models/data-company-interface';
import { lawyer } from './models/layer-class';
import { UserCreate } from './models/user-create-class';
import { DataSubscriber } from './models/data-subscriber-interface';
import { DataCompanys } from './models/data-company-class';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

    //companyData:Array<string>=new Array<string>();
    //companyData:DataCompany;
    companyData:DataCompanys;
    layerData:lawyer;
    userData:UserCreate;
    subsData:DataSubscriber;

    messageSource: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() {


     }

     getCompanyData(){
      return this.companyData;
     }

     setCompanyData(company:DataCompanys){
      this.companyData=company;
     }

     getLayerData(){
      return this.layerData;
     }

     setLayerData(layer:lawyer){
      this.layerData=layer;
     }

     getUserData(){
      return this.userData;
     }

     setUserData(user:UserCreate){
      this.userData=user;
     }

     getDataSubscriber(){
       return this.subsData;
     }

     setDataSubscriber(subs:DataSubscriber){
      this.subsData=subs;
     }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { UserInterface } from "../models/user-interface";
import {ErrorStateMatcher} from '@angular/material/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import * as sha512 from 'js-sha512';
import {TranslateService} from '@ngx-translate/core';

import {SharedService} from 'src/app/shared.service'

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private isUserLoggedIn;
  matcher = new MyErrorStateMatcher();
  accountFormControl: FormControl;
  passFormControl: FormControl;
  message: any;
  public isError = false;

  constructor(private authService: AuthService,
              private router: Router,
              private translate: TranslateService,
              private _sharedService: SharedService) {
                translate.setDefaultLang('es');
               }
  public user: UserInterface = {
    id:"",
    email: "",
    account:"",
    passphrase:"",
    role_id: "",
    fullname: "",
    token: "",
    type:"",
    reference:"",
    specialties:[],
    privilegies: [],
  };


  ngOnInit() {

    this.accountFormControl = new FormControl('', [
      Validators.required,
      //Validators.email,
      Validators.minLength(4),
      Validators.maxLength(50),
    ]);

    this.passFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(50),
      // Validators.pattern('[a-zA-Z0-9-" "]+')
    ]);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    console.log("use lenguaje Login "+language)
    this._sharedService.messageSource.next(language);
  }

  onLogin(){
    var cifrada = sha512.sha512(this.user.passphrase);
    return this.authService.loginUser(this.user.account, cifrada)
    .subscribe(data =>{
      if(data.status.code===200 && data.status.message==="Ok!"){
        let u: UserInterface = {id: data.content.id,
          email: this.user.email,
          role_id: data.content.role_id,
          fullname: data.content.firstname,
          token: data.content.token,
          type: data.content.type,
          reference: data.content.reference,
          privilegies: data.content.privilegies,
          status_id: data.content.status_id,
          status_name: data.content.status_name,
          phone: data.content.phone,
          role_name: data.content.role_name,
          specialties: data.content.specialties
          };
        this.authService.SetUser(u);
        this.onLoginRedirect(u.role_name);
      }else{
        alert("falla del sistema, revise sus campos e inetente nuevamente")
      }
    },
      error =>  {this.handleError(error)}
    );
  }

  private handleError(error: HttpErrorResponse) {
    this.isError = true;
    this.message= error.error.status.message;
    setTimeout(()=>{
      this.isError = false;
    },4000);
  };

  onLoginRedirect(role_name: any): void{

    console.log('este es el rol',role_name)
    switch (role_name) {
      case ('Administrador'):
        this.router.navigate(['/dashboard/home/casescenter']);
        break;
      case ('Abogado Lider'):
        this.router.navigate(['/dashboard/home/casescenter']);
        break;
      case ('Abogado'):
        this.router.navigate(['/dashboard/home/casescenter']);
        break;
      case ('Cliente'):
        this.router.navigate(['/dashboard/home/casescenter']);
        break;
    }
  }

}

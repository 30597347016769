import { Component, OnInit } from '@angular/core';
import {MatDialogRef,MatDialog} from "@angular/material";
import { FormControl, FormGroup, Validators,FormBuilder,FormGroupDirective, NgForm } from '@angular/forms';
import {lawyer} from 'src/app/models/layer-class';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {DataApiService} from 'src/app/services/data-api.service';
import {DataSpecialties, DataSpecialtiesContent} from 'src/app/models/data-specialties-class';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { delay } from 'q';
import {DataLawyersContent, DataLawyers} from 'src/app/models/data-lawyer-class';
import { AuthService } from 'src/app/services/auth.service';
import {UserInterface} from 'src/app/models/user-interface';
import { Reference } from '@angular/compiler/src/render3/r3_ast';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-lawyer-create',
  templateUrl: './lawyer-create.component.html',
  styleUrls: ['./lawyer-create.component.css']
})
export class LawyerCreateComponent implements OnInit {

  breakpoint: number;
  breakpointbig: number;
  result:String;
  newLawyerValidations: FormGroup;
  Nombre:String;
  Apellido:String;
  Telefono:String;
  document: string;
  //Especialidad:String;
  Email:String;
  NombreDeUsuario:String;
  // Clave:String;
  // ConfirmacionCLave:String;
  resultCheck:boolean;
  dataSpecialties:DataSpecialtiesContent[];
  selectSpecialty: Array<string>;
  specialty: DataSpecialties;

  //resumeData:lawyer=new lawyer();
  matcher = new MyErrorStateMatcher();
  myArrStr:string;
  toppings = new FormControl();
  public type: string;
  toppings2 = new FormControl();
  currentUser : UserInterface;
  currentToken: string;
  newUser: string;

  constructor(public dialogRef: MatDialogRef<LawyerCreateComponent>,
    public dialog: MatDialog,private translate: TranslateService
    ,private _sharedService:SharedService,private formBuilder: FormBuilder,
    private dataApi: DataApiService,
    private as: AuthService) {
      //translate.setDefaultLang('es');
      //valor por defecto si el usuario no selecciona nada
      this.type="e3b7a016-d331-4f89-a98d-91439a33ed14"
      this.currentUser=this.as.getCurrentUser();
      this.currentToken=this.currentUser.token;
     }

  ngOnInit() {

    this.newLawyerValidations = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      lastname: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
     phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]], 
      //specialty: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      email: ['', [Validators.required,Validators.minLength(4),Validators.email]],
      nameuser: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      document: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[0-9-" "]+')]],
      //password: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      //passwordc: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      }, /* { validator: this.checkPasswords } */);

    this.breakpoint = (window.innerWidth <= 620) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint init "+this.breakpoint+" "+window.innerWidth)
    console.log("breakpointbig init "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '60%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('48%','65%');
    }
    //this.creteFormGroup();
    this.getSpecialties();
    }

   onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 850) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint "+this.breakpoint)
    console.log("breakpointbig "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('95%', '90%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('48%','65%');
    }else{
      this.dialogRef.updateSize('40%','60%');
    }
  }

  onClose() {

    this.dialogRef.close();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LawyerCreateComponent, {
       width: '35%'

    });
  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  onClean(){
    this.Nombre='';
    this.Apellido='';
    this.Telefono='';
    this.selectSpecialty=[];
    this.Email='';
    this.NombreDeUsuario='';
    this.document='';
    // this.Clave='';
    // this.ConfirmacionCLave='';
  }


  onSummit(){
    // console.log("clave"+this.Clave+" confirmacion  "+this.ConfirmacionCLave);

    // if(this.Clave==this.ConfirmacionCLave){
      console.log("por aca se imprime "+this.Nombre);
      console.log("por aca se imprime "+this.Apellido);
      console.log("por aca se imprime "+this.Telefono);
      console.log("por aca se imprime "+this.selectSpecialty);
      console.log("por aca se imprime "+this.Email);
      console.log("por aca se imprime "+this.type);
      console.log("por aca se imprime "+this.NombreDeUsuario);
    if(typeof this.Nombre !== 'undefined'
    &&typeof this.Apellido !== 'undefined'
    &&typeof this.Telefono !== 'undefined'
    &&typeof this.selectSpecialty !== 'undefined'
    &&typeof this.Email !== 'undefined'
    &&typeof this.type !== 'undefined'
    &&typeof this.NombreDeUsuario !== 'undefined'
    &&typeof this.document !== 'undefined'
    // &&typeof this.Clave !== 'undefined'
    // &&typeof this.ConfirmacionCLave !== 'undefined'
    ){
      if (this.type =='1'){
        console.log ('soy lider')
        this.type = '020226d9-2662-4143-b0d1-30ec96226bd1'
      }else{
        console.log ('soy abogado')
        this.type = 'e3b7a016-d331-4f89-a98d-91439a33ed14'
      }

      console.log("ultima impresion "+this.type)
      var dataLawyer =
      {
        "person":{
          "email": this.Email,
          "person_id":this.document,
          "first_name": this.Nombre,
          "last_name": this.Apellido,
          "phone": this.Telefono,
        },
        "role_id": this.type,
        "account":this.NombreDeUsuario,
        "token": this.currentToken
      };

      return this.dataApi.setNewLawyers(dataLawyer).subscribe(response=>{
        console.log("response "+response);
        if(response.status.code==200 ){
          this.newUser = response.content.id
          console.log(this.newUser)
          console.log(this.selectSpecialty[0])
          this.setEspecialtiesUser(this.newUser,this.selectSpecialty);
          //this.onClose();
        }else{
          alert("falla del sistema, revise sus campos e inetente nuevamente")
        } error=>{
          console.log("Error delete case"+error)
          this.handleError(error);
        }
      });

    }

  }

 setEspecialtiesUser(user,specialty){
    for(var i = 0, len = specialty.length; i < len; i++)
    {
      console.log(user)
      console.log (specialty)
      console.log("Selected id "+specialty[i]);
      let idspecialty= specialty[i];
      var dataSpecialty=
      {
        "user_id": user,
        "specialty_id": idspecialty
      }
      console.log ("esta es la data que va al servicio "+dataSpecialty)
       this.dataApi.postSpecialtyGrantUser(dataSpecialty).subscribe(response=>{
          console.log("data especialty set "+response);
          if(response.status.code===200 && response.status.message==="Ok!"){
            //this.onClose();
          }else{
            alert("falla del sistema, revise sus campos e inetente nuevamente")
          } error=>{
            console.log("Error delete case"+error)
            this.handleError(error);
          }
          // this.myArrStr= JSON.stringify(response);
          // console.log("data reposable set "+this.myArrStr);
      })
    }
    this.onClose();
  }

  getSpecialties(){
    this.dataApi.getSpecialtiesData().subscribe(data=>{

      if(data.status.code==200 && data.status.message=="Ok!"){
      this.dataSpecialties=data.content;
      this.myArrStr= JSON.stringify(data.content);
      //console.log("respuesta de Especialidades "+this.myArrStr);
      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }


    },
    error=>{
      console.log("Error get Status"+error)
      this.handleError(error);
    }
    )
  }

  getSpecialtiesData(name,id){
    console.log(name+" "+id)
    console.log(this.selectSpecialty)
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.Telefono = obj;
  }

}

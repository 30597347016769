import { Component, OnInit,Input,AfterViewInit,Output  } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { EventEmitterService } from 'src/app/event-emitter.service';
import { Router,ActivatedRoute } from '@angular/router';
import {SharedService} from 'src/app/shared.service';
import { AuthService } from 'src/app/services/auth.service'

declare var jQuery;
declare var SmoothlyMenu;


@Component({
  selector: 'app-dashboard-top-menu',
  templateUrl: './dashboard-top-menu.component.html',
  styleUrls: ['./dashboard-top-menu.component.css']
})



export class DashboardTopMenuComponent implements AfterViewInit {

  wastranslate:boolean;

  constructor(private translate: TranslateService,
    private router:ActivatedRoute,private routerone:Router,
    private _sharedService: SharedService) {
      translate.setDefaultLang('es');

    // this.router.url.subscribe(data=>{
    //   this.showdata(data)
    //   console.log("extras dashboard top 2 "+data)})


  }



  useLanguage(language: string) {
    this.translate.use(language);
    console.log("extras dashboard top "+language)
  }


  // ngOnInit() {
  // }

  ngAfterViewInit() {
  console.log("ngAfterViewInit top menu  ")

  }

  toggleMenu(){
    console.log("evento onclick");
    jQuery("body").toggleClass("mini-navbar");
    SmoothlyMenu();
  }

  logout(){
    localStorage.clear();
    this.routerone.navigate(['/login']);
  }
}


import { Component, OnInit,ViewChild } from '@angular/core';
import {MatDialogRef,MatDialog} from "@angular/material";
import {MatSelect} from "@angular/material/select";

import { FormControl, FormGroup, Validators,FormBuilder,FormGroupDirective, NgForm } from '@angular/forms';

import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';

import {ErrorStateMatcher} from '@angular/material/core';

import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable, throwError } from 'rxjs';

import {DataApiService} from 'src/app/services/data-api.service';
import { DataStatus, DataStatusContent } from 'src/app/models/data-status-class';
import { DataCategory, DataCategoryContent } from 'src/app/models/data-category-class';
import { DataCasesSet } from 'src/app/models/setdata-cases-class';
import {AuthService} from 'src/app/services/auth.service';
import {UserInterface} from 'src/app/models/user-interface';
import { DataResposables, DataResposablesContent } from 'src/app/models/data-responsables-class';
import {DataCompanys,DataCompanysContent} from 'src/app/models/data-company-class';
import {DataClient,DataClientContent} from 'src/app/models/data-client-class';
import { DataClasesDetail, DataClasesDetailContent } from 'src/app/models/data-cases-detail-class';
import {DataContacAddedDetail,DataContacAddedDetailContent} from 'src/app/models/data-contac-added';
declare var $;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface Category {
  value: string;
  viewValue: string;
}

export class DateValidator {

  static ptDate(control: FormControl): { [key: string]: any } {
      let ptDatePattern =  /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

      if (!control.value.match(ptDatePattern))
          return { "ptDate": true };

      return null;
  }

  static usDate(control: FormControl): { [key: string]: any } {
      let usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

      if (!control.value.match(usDatePattern))
          return { "usDate": true };

      return null;
  }
}

@Component({
  selector: 'app-case-create',
  templateUrl: './case-create.component.html',
  styleUrls: ['./case-create.component.css']
})
export class CaseCreateComponent implements OnInit {

  breakpoint: number;
  breakpointbig: number;
  result:String;
  responsables:Array<string>=[];

  contacs:Array<String>;
  Datastatus:DataStatusContent[];
  DataComapny:DataCompanysContent[];
  Datacategory:DataCategoryContent[];
  DataResposables:DataResposablesContent[]=[];
  indexSave:number;
  toppings = new FormControl();
  // toppingList: string[] = ['Javier Barrios', 'Fernando Mandoloni', 'Roberto Rosales', 'Adriana Peraza', 'Elber Galarga'];
  newCaseValidations:FormGroup;
  CreationNumber:string;
  CreationDate:string;
  UserWhoCreatedIt:string;
  Status:string;
  StatusId:string;
  CaseFile:string;
  Title:string;
  Description:string;
  //Resposable:Array<string>;
  Defendant:string;
  Court:string;
  Category:string;
  CategoryId:string;
  userId:string;
  dataSetFormData:DataCasesSet=new DataCasesSet();
  currentUser:UserInterface;
  companyName:string;
  companyId:string;
  //type:string;

  myArrStr:string;
  myArrStr1:string;
  showContact:boolean;
  DataClient:DataClientContent[]=[];
  refrenceCasesCreated:string;

  clientType:string;
  clientReference:string;
  clientId:string;
  DataContacsAdded:DataContacAddedDetailContent[];
  topicId:string;
  contacsAdded:Array<string>=[];
  contacsNameAdded:Array<string>=[];

  rowsNum: number;
  pageNum: number;
  totalPage: number;
  totalElements: number;
  rowsNum1: number;
  pageNum1: number;
  totalPage1: number;
  totalElements1: number;


  @ViewChild('mySelec',{static:false}) private mySelec:MatSelect;

  constructor(public dialogRef: MatDialogRef<CaseCreateComponent>,
    private formBuilder: FormBuilder,private translate: TranslateService
    ,private _sharedService:SharedService
    ,public as:DataApiService,
    public authservice:AuthService) {
    //this.responsables=new Array();
    this.currentUser=this.authservice.getCurrentUser();
    this.indexSave=0;
    this.userId=this.currentUser.id;
    console.log("user Id "+this.userId);
    translate.setDefaultLang('es');
    this.CategoryId="88e14f98-1fe3-4fc0-8b17-53b3fb58aa7c"//category by default
    this.showContact=false;
    this.clientType='';
    this.clientReference='';

   }

   makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

   selected = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectedd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControld = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControld = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selecteddd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControldd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControldd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);


  matcher = new MyErrorStateMatcher();


  ngOnInit() {



    this.newCaseValidations = this.formBuilder.group({
      // creationumber: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(15), Validators.pattern('[0-9-" "]+')]],
      // creationdate: ['',[Validators.required,DateValidator.ptDate]],
      //createby: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      //status: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      fileexp: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      title: ['', [Validators.required,Validators.minLength(1), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.´]+')]],
      description: ['', [Validators.required,Validators.maxLength(100), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      defedant: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      court: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      });

    this.breakpoint = (window.innerWidth <= 620) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint init "+this.breakpoint+" "+window.innerWidth)
    console.log("breakpointbig init "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '60%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('50%',);
    }

    this.StatusId="40000000-0000-0000-0000-000000000000";//by default for all create cases
    //this.getCompanyData();
    this.getCategoryData();
    this.rowsNum=20;
    this.pageNum=0;
    this.rowsNum1=20;
    this.pageNum1=0;
    this.getClientData(this.rowsNum,this.pageNum);
    this.getResponsablesData(this.rowsNum1,this.pageNum1);


  }

  getNextBatch1(){
    console.log(" llego al final del scroll responsables ")
    setTimeout(() => {

      if(this.pageNum1<this.totalPage1 && this.totalElements1>0){
        this.pageNum1=this.pageNum1+1;
        console.log(" saltar a la pagina "+this.pageNum1)
        this.getResponsablesData(this.rowsNum1,this.pageNum1);

      }

      }, 200);

  }

  getNextBatch() {
    console.log(" llego al final del scroll ")

    setTimeout(() => {
    //this.mySelec.close();
    if(this.pageNum<this.totalPage && this.totalElements>0){
      this.pageNum=this.pageNum+1;
      console.log(" saltar a la pagina "+this.pageNum)
      this.getClientData(this.rowsNum,this.pageNum);
      //this.mySelec.open();

    }

    }, 200);

  }



  getClientData(numPage,pageNum){

    // this.as.getUsersClientData().subscribe(data=>{
       this.as.getUsersClientData(numPage,pageNum).subscribe(data=>{
       console.log(" get user client"+data.content)
       if(data.status.code==200 && data.status.message=="Ok!"){
        this.DataClient=this.DataClient.concat(data.content);
        this.totalPage =data.pagination.count_page;
        this.totalElements = data.pagination.count_records;
        //this.mySelec.open();
        console.log("total de paginas del servicio "+this.totalPage+" total de elementos del servicio "+this.totalElements )
      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }

     },
     error=>{
       console.log("Error get Status"+error)
       this.handleError(error);
     }
     )


   }

   getClientText(client,clientid,clienttype,clientreference){

    //console.log("Cliente "+client+" id "+clientid);
    this.clientId=clientid;
    this.clientType=clienttype;
    this.clientReference=clientreference;

    console.log("Cliente "+client+" id "+this.clientId
    +" type "+this.clientType+" reference "+this.clientReference);
    //this.getClientDetailWithContact(this.clientId);
    this.getContactAdded(this.clientId)

   }

   getContactAdded(clientId){

    this.as.getContacsAdded(clientId).subscribe(data=>{
       console.log("data "+data)

      if(data.status.code==200 && data.status.message=="Ok!"){

       if(data.content.length>0){
          this.myArrStr1= JSON.stringify(data.content);
          console.log("size contac data "+data.content.length)
          this.DataContacsAdded=data.content;
          this.contacsAdded=[];
          this.contacsNameAdded=[];
          for(var i = 0, len = data.content.length; i < len; i++)
        {
          this.contacsAdded.push(data.content[i].person_id)
          this.contacsNameAdded.push(data.content[i].contact_full_name)
          console.log("ids contacs added "+data.content[i].person_id)
        }
          console.log("Conatacts added "+this.contacsAdded.length)
          console.log("Conatacts name added "+this.contacsNameAdded.length)
       }else{
          this.DataContacsAdded = undefined;
          alert("Este cliente no tiene contactos registrados")


        }


    }else{
      alert("falla del sistema SECA, intente nuevamente");
    }
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )
  }

  // getCompanyData(){

  //   this.as.getCompanyData(20,0).subscribe(data=>{

  //     this.DataComapny=data.content;

  //   },
  //   error=>{
  //     console.log("Error get Status"+error)
  //     this.handleError(error);
  //   }
  //   )

  // }

  getResponsablesData(numPage,pageNum){

  this.as.getResponsablesData(numPage,pageNum).subscribe(data=>{

    if(data.status.code==200 && data.status.message=="Ok!"){
      this.DataResposables=this.DataResposables.concat(data.content);
      this.totalPage1 =data.pagination.count_page;
      this.totalElements1 = data.pagination.count_records;
      console.log("total de paginas responsables "+this.totalPage1+" total de elementos responsables "+this.totalElements1 )
    }else{
      alert("falla del sistema SECA, intente nuevamente");
    }

    },
    error=>{
      console.log("Error get Status"+error)
      this.handleError(error);
    }
    )

  }

  getCategoryData(){

    this.as.getCategoryData().subscribe(data=>{

      this.myArrStr= JSON.stringify(data);
      this.myArrStr1= JSON.stringify(data.content);
      //console.log("Categorys: "+this.myArrStr1)
      if(data.status.code==200 && data.status.message=="Ok!"){
        this.Datacategory=data.content;
      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }



    },
    error=>{
      console.log("Error get Status"+error)
      this.handleError(error);
    }
    )

  }

  onClose() {

    this.dialogRef.close();
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 850) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint "+this.breakpoint)
    console.log("breakpointbig "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('95%', '95%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('50%');
    }else{
      this.dialogRef.updateSize('45%');
    }
  }

  getInnerText(innerText,id){
    console.log("Categoria "+innerText+" id "+id )
    this.Category=innerText;
    this.CategoryId=id;
  }

  getStatusText(innerText,id){
    console.log("Company "+innerText+" company ID "+id);
    this.companyName=innerText;
    this.companyId=id;
    //this.StatusId=id;

  }

  getContactText(name,id){
    console.log(name+" "+id)
    console.log("Array "+this.contacs)
  }



  getResponsables(first_name,index){
    console.log(first_name+" "+index)
    console.log("Array responsables "+this.responsables)

    // for(var i = 0, len = this.responsables.length; i < len; i++)
    //     {
    //       //console.log("Selected id "+this.responsables[i]);
    //       let toResponsables =  this.responsables[i].split("/");
    //       let type=toResponsables[0];
    //       let nameid=toResponsables[1];
    //       let idrole=toResponsables[2];
    //       // let prov=toResponsables[1].split("/");
    //       // let nameid=prov[0];
    //       // let idrole=prov[1];
    //       console.log("type "+type+" nameId "+nameid+" idRole "+idrole);

    //     }

  }

  onClean(){

    // this.CreationNumber=''
    // this.CreationDate=''
    // this.UserWhoCreatedIt=''
    //this.Status=''
    this.CaseFile=''
    this.Title=''
    this.Description=''
    //this.Resposable='';
    this.Defendant=''
    //this.Category=''
    this.Court=''

  }

  onSummit(){
    console.log("onSummit")

    this.setCaseDataService(this.userId,this.StatusId,
      this.CategoryId,this.CaseFile,this.Title,this.Defendant,this.Description,this.Court);


    //this.setResponsanbleToCase(this.responsables)

    //this.onClose();
  }

  setResponsanbleToCase(responsables){

    for(var i = 0, len = responsables.length; i < len; i++)
        {
          //console.log("Selected id "+this.responsables[i]);
          let toResponsables =  this.responsables[i].split("/");
          let type=toResponsables[0];
          let nameid=toResponsables[1];
          let idrole=toResponsables[2];
          let relation:string;
          console.log("type "+type+" nameId "+nameid+" idRole "+idrole);
          let topicId=this.refrenceCasesCreated;
          if(idrole.includes( "e3b7a016-d331-4f89-a98d-91439a33ed14")){
            relation="2";
          }else{
             relation="3";
          }
          console.log("relation "+relation);
          //this.as.postResposableCases(type,nameid,topicId,idrole)
          this.as.postResposableCases(type,nameid,topicId,relation)
          .subscribe(response=>{
              console.log("data reposable set "+response);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data reposable set "+this.myArrStr);
              }else{
                alert("falla del sistema SECA, intente nuevamente");
              }

          },error=>{

            this.handleError(error);
          }

          )

        }
        this.onClose();

  }

  setCaseDataService(userId,StatusId,CategoryId,casefile,Title,defedant,Description,Court){
    var dataCases =
                     {
                      "user_id": userId,
                      "status_id": StatusId,
                      "category_id": CategoryId,
                      "expedient": casefile,
                      "title": Title,
                      "defendants":defedant,
                      "court": Court,
                      "synopsis": Description
                     };

    console.log("Json data cases "+dataCases);

    const obj = {
      userId,
      StatusId,
      CategoryId,
      casefile,
      Title,
      Court,
      Description,

    };
    console.log("Objeto "+obj);

       // this.as.setCaseData(userId,StatusId,CategoryId,casefile,Title,Description).subscribe(data=>{
      this.as.setCaseData(dataCases).subscribe(response=>{
        console.log("response "+response);
        this.myArrStr= JSON.stringify(response.status);
        console.log("response "+this.myArrStr);
        let myObj = JSON.parse(this.myArrStr);
        console.log("JSON2  "+myObj.code+" "+myObj.message);
        if(myObj.code===200 && myObj.message==="Ok!"){

          this.refrenceCasesCreated=response.reference;
          console.log("Reference Case "+this.refrenceCasesCreated)
          if(this.responsables.length>0){
            this.setResponsanbleToCase(this.responsables)
          }else{
            console.log("No agrego responsables")
          }

          this.topicId=response.reference;
          if(this.clientType!='' && this.clientReference!=''){
            this.setClientTocase(this.clientType,this.clientReference,this.topicId);
          }else{
            console.log("No agrego clientes")
          }

          //this.onClose();
        }else{
          alert("falla del sistema, revise sus campos e inetente nuevamente")
        } error=>{
          console.log("Error delete case"+error)
          this.handleError(error);
        }
      });
  }

setClientTocase(clientType,clientReference,topicId){
    this.as.postClientToCases(clientType,clientReference,topicId)
    .subscribe(resp=>{
      console.log("data client set to case "+resp.status.code);
      if(resp.status.code==200 && resp.status.message=="Ok!"){
        if(this.contacsAdded.length>0){
          console.log("has contact true ");
          this.setContactClientToCase(this.contacsAdded,topicId);
        }else{
          alert("Este cliente no tiene contactos")
          this.onClose();
        }
      }else{
        alert("Falla del sistema SECA, intente de nuevo")
      }


    },error=>{
      this.onClose();
      this.handleError(error);
    }

    )

  }

  setContactClientToCase(contacs,caseId){
    console.log("# contact "+contacs.length);
    for(var i = 0, len = contacs.length; i < len; i++)
        {
          console.log("Selected person id "+contacs[i]);
          //console.log("Selected person id "+this.contacs.length);
          let contacIdprov=contacs[i];
          this.as.postContacsToCases(contacIdprov,caseId)
          .subscribe(response=>{
            if(response.status.code==200 && response.status.message=="Ok!"){
              console.log("data contact set "+response.reference);
            }else{
              alert("Falla del sistema SECA, intente de nuevo")
            }

          },error=>{

            this.handleError(error);
          }

          )

         }
         this.onClose();

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}

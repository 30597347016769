import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import {SharedService} from 'src/app/shared.service'

@Component({
  selector: 'app-dashboard-footer',
  templateUrl: './dashboard-footer.component.html',
  styleUrls: ['./dashboard-footer.component.css']
})
export class DashboardFooterComponent implements OnInit {

  constructor(private translate: TranslateService,
    private router:ActivatedRoute,private routerone:Router,
    private _sharedService: SharedService) {

      translate.setDefaultLang('es');

    // this.router.url.subscribe(data=>{
    //   this.showdata(data)
    //   console.log("extras dashboard top 2 "+data)})

      // this._sharedService.messageSource.subscribe((message:string)=>{

      //   console.log("Mensaje de "+message);
      //   if(message!=''){
      //     this.useLanguage(message);
      //   }else{
      //     this.useLanguage('en');
      //   }

      // });
     }

     useLanguage(language: string) {
      this.translate.use(language);
      console.log("extras dashboard footer "+language)
      this._sharedService.messageSource.next(language);
    }

  ngOnInit() {
  }

}

import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import {MatDialogRef,MatDialog, MAT_DIALOG_DATA,MatOption} from "@angular/material";
import { FormControl, FormGroup, Validators,FormBuilder,FormGroupDirective, NgForm } from '@angular/forms';

import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';

import {ErrorStateMatcher} from '@angular/material/core';

import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable, throwError } from 'rxjs';

import {DataApiService} from 'src/app/services/data-api.service';
import { DataStatus, DataStatusContent } from 'src/app/models/data-status-class';
import { DataCategory, DataCategoryContent } from 'src/app/models/data-category-class';
import { DataCasesSet } from 'src/app/models/setdata-cases-class';
import {AuthService} from 'src/app/services/auth.service';
import {UserInterface} from 'src/app/models/user-interface';
import { DataResposables, DataResposablesContent } from 'src/app/models/data-responsables-class';
import {DataCompanys,DataCompanysContent} from 'src/app/models/data-company-class';
import {DataClient,DataClientContent} from 'src/app/models/data-client-class';
import { DataClasesDetail, DataClasesDetailContent } from 'src/app/models/data-cases-detail-class';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
declare var $;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-case-update',
  templateUrl: './case-update.component.html',
  styleUrls: ['./case-update.component.css']
})
export class CaseUpdateComponent implements OnInit {

  breakpoint: number;
  breakpointbig: number;
  result:String;
  resultInsert:String;
  responsables:Array<string>=[];

  contacs:Array<String>;
  Datastatus:DataStatusContent[];
  DataComapny:DataCompanysContent[];
  DataClient:DataClientContent[];
  DataContac:DataClientContent[];
  Datacategory:DataCategoryContent[];
  DataResposables:DataResposablesContent[]=[];
  DataResposablesAdded:DataResposablesContent[];
  indexSave:number;
  toppings = new FormControl();
  // toppingList: string[] = ['Javier Barrios', 'Fernando Mandoloni', 'Roberto Rosales', 'Adriana Peraza', 'Elber Galarga'];
  newCaseValidations:FormGroup;
  CreationNumber:String;
  CreationDate:String;
  UserWhoCreatedIt:String;
  Status:String;
  StatusId:String;
  CaseFile:String;
  Title:String;
  Description:String;
  //Resposable:Array<string>;
  Defendant:String;
  Category:String;
  CategoryId:String;
  userId:string;
  dataSetFormData:DataCasesSet=new DataCasesSet();
  currentUser:UserInterface;
  companyName:string;
  caseId:string;
  createById:string;

  type:string;

  CaseDetailData:DataClasesDetailContent;

  id: string;
  user_id:string;
  status_id:string;
  category_id:string;
  expedient:string;
  title:string;
  synopsis:string;
  created_at:string;
  updated_at:string;
  category:string;
  files:any;
  participants:any;
  status:any;
  defendants:string;
  Court:string;
  tracking:any;
  user:any;
  myArrStr:string;
  myArrStr1:string;
  resp:boolean;
  respClient:boolean;
  selectedResponsables:boolean;
  selectedClient:boolean;
  clientType:string;
  clientReference:string;
  arrayKyeContacs:Array<string>;
  arrayKyeSelectedContacs:Array<string>;
  arrayNameContacs:Array<string>;
  clientId:string;
  showContact:boolean;

  rowsNum1: number;
  pageNum1: number;
  totalPage1: number;
  totalElements1: number;
  needAddContac:boolean;
  contacsAdded:Array<string>=[];
  contacsNameAdded:Array<string>=[];

  @ViewChild('allSelected',{static:false}) private allSelected: MatOption;
  @ViewChild('mySelec',{static:false}) private mySelec:any;


  constructor(public dialogRef: MatDialogRef<CaseUpdateComponent>,
    private formBuilder: FormBuilder,private translate: TranslateService
    ,private _sharedService:SharedService,public as:DataApiService,
    public authservice:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DataUpdateCaseModel,
    public dialog: MatDialog) {
    //this.responsables=new Array();
    this.currentUser=this.authservice.getCurrentUser();
    this.indexSave=0;
    this.userId=this.currentUser.id;
    console.log("user Id "+this.userId);
    translate.setDefaultLang('es');
    this.type="users";
    this.caseId=data.id
    console.log("case Id for detail "+this.caseId);
    this.resp=false;
    this.respClient=false;
    this.selectedResponsables=false;
    this.selectedClient=false;
    this.showContact=true;
   }

   makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

   selected = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectedd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControld = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControld = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selecteddd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControldd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  nativeSelectFormControldd = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);


  matcher = new MyErrorStateMatcher();

  ngOnInit() {

    this.newCaseValidations = this.formBuilder.group({
      // creationumber: ['', [Validators.required,Validators.minLength(1), Validators.maxLength(15), Validators.pattern('[0-9-" "]+')]],
      // creationdate: ['',[Validators.required,DateValidator.ptDate]],
      //createby: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      //status: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      fileexp: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      title: ['', [Validators.required,Validators.minLength(1), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      description: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(100), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      defedant: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      court: ['', [Validators.required,Validators.minLength(4),Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      });

    this.breakpoint = (window.innerWidth <= 620) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint init "+this.breakpoint+" "+window.innerWidth)
    console.log("breakpointbig init "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '60%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('50%',);
    }

    this.getCaseDetailData();
    this.getStatusData();
    this.getResposablesAdded(this.caseId,1);
    //this.getCompanyData();
    //this.getClientData();
    this.rowsNum1=20;
    this.pageNum1=0;
    this.getResponsablesData(this.rowsNum1,this.pageNum1);
  }

  getNextBatch1(){
    console.log(" llego al final del scroll responsables ")
    setTimeout(() => {

      if(this.pageNum1<this.totalPage1 && this.totalElements1>0){
        this.pageNum1=this.pageNum1+1;
        console.log(" saltar a la pagina "+this.pageNum1)
        this.getResponsablesData(this.rowsNum1,this.pageNum1);

      }

      }, 200);

  }

  getResposablesAdded(topicId,iden){

    this.as.getResponsablesInTopicData(topicId).subscribe(data=>{
      console.log(" get user client"+data.content)
      if(data.status.code==200 && data.status.message=="Ok!"){

        if(data.content.length>0){
          this.myArrStr1= JSON.stringify(data.content);
          console.log("size contac data "+data.content.length)
          this.DataResposablesAdded=data.content;
          if(iden==2){
            console.log("reset arrays ")
            this.contacsAdded=[];
            this.contacsNameAdded=[];
          }
          for(var i = 0, len = data.content.length; i < len; i++)
        {
          this.contacsAdded.push(data.content[i].reference)
          this.contacsNameAdded.push(data.content[i].reference_name)
          console.log("ids resposables added "+data.content[i].reference)
          console.log("ids resposables added "+data.content[i].reference_name)
        }
          console.log("Responsables added "+this.contacsAdded.length)
          console.log("Responsables name added "+this.contacsNameAdded.length)

        }else{
          alert("Este caso no tiene responsables registrados")
          this.needAddContac=true;
        }

      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }

    },
    error=>{
      console.log("Error get Status"+error)
      this.handleError(error);
    }
    )

  }

  // getClientData(){

  //  // this.as.getUsersClientData().subscribe(data=>{
  //     this.as.getClientData().subscribe(data=>{
  //     console.log(" get user client"+data.content)
  //     this.DataClient=data.content;
  //   },
  //   error=>{
  //     console.log("Error get Status"+error)
  //     this.handleError(error);
  //   }
  //   )

  // }

  getResponsablesData(numPage,pageNum){

    this.as.getResponsablesData(numPage,pageNum).subscribe(data=>{

      if(data.status.code==200 && data.status.message=="Ok!"){
        this.DataResposables=this.DataResposables.concat(data.content);
        this.totalPage1 =data.pagination.count_page;
        this.totalElements1 = data.pagination.count_records;
        console.log("total de paginas responsables "+this.totalPage1+" total de elementos responsables "+this.totalElements1 )
      }else{
        alert("falla del sistema SECA, intente nuevamente");
      }

      },
      error=>{
        console.log("Error get Status"+error)
        this.handleError(error);
      }
      )

    }

  // getCompanyData(){

  //   this.as.getCompanyData(20,0).subscribe(data=>{

  //     this.DataComapny=data.content;

  //   },
  //   error=>{
  //     console.log("Error get Status"+error)
  //     this.handleError(error);
  //   }
  //   )

  // }

  getResponsables(first_name,index){
    console.log(first_name+" "+index)
    console.log("Array responsables "+this.responsables)

    for(var i = 0, len = this.contacsAdded.length; i < len; i++)
        {

          console.log("Responsables "+this.contacsAdded[i]+" index "+index)
          if(this.contacsAdded[i]==index){
            console.log("Este responsable "+this.contacsNameAdded[i]+" ya esta asociado al caso deseas borrarlo")

            this.confirmDialog("Este responsable "+this.contacsNameAdded[i]+" ya esta asociado al caso deseas borrarlo?"
            ,this.contacsAdded[i]);
            console.log("id case "+this.caseId)

            this.needAddContac=false;
            console.log("no need add responsable new "+this.needAddContac)
            //this.ngOnInit();
            break;
          }else{

            this.needAddContac=true;
            console.log(" need add responsable new "+this.needAddContac)
          }

        }

        console.log(" result add contact  "+this.needAddContac)
        if(this.needAddContac){
        //add cointact to client
        console.log("Este responsable "+first_name+" no esta asociado al caso deseas asociarlo?")
        this.confirmDialogInsert("Este responsable "+first_name+" no esta asociado al caso deseas asociarlo?",index)
        }

  }

  unselect(): void {
    this.responsables = undefined;
    console.log("contactos unselec "+this.contacs)
 }

  confirmDialog(messagedata,contactWho): void {
    const message = messagedata;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef1 = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef1.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      console.log("resultado "+this.result);
      if(this.result){
      //this.deleteItem(index);
      setTimeout(() => {
        //this.allSelected.deselect();
        this.unselect();
        console.log("disselct selection ");
        console.log("responsable who "+contactWho);
        console.log("case who "+this.caseId);
        this.removeContacToClient(this.caseId,contactWho);
        // this.mySelec.close();
        // this.getContactAdded(this.idCompanyFromUser,2);


      }, 500);

    }else{
      this.unselect();
    }

    });
  }

  confirmDialogInsert(messagedata,contactWho): void {
    const message = messagedata;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRefInsert = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefInsert.afterClosed().subscribe(dialogResultIns => {
      this.resultInsert = dialogResultIns;
      console.log("resultado "+this.resultInsert);
      if(this.resultInsert){
      //this.deleteItem(index);
      setTimeout(() => {
        console.log("Add new contact ");
        console.log("resposable who "+contactWho);
        console.log("case who "+this.caseId);

        this.addContacToClient(this.caseId,contactWho);
        this.unselect();
        // this.mySelec.close();
        // this.getContactAdded(this.idCompanyFromUser,2);

      }, 500);

    }else{
      this.unselect();
      console.log("do nothing add contact")
      //this.unselect();
    }

    });
  }

  setResponsanbleToCase(responsables){

    for(var i = 0, len = responsables.length; i < len; i++)
        {
          //console.log("Selected id "+this.responsables[i]);
          let toResponsables =  this.responsables[i].split("/");
          let type=toResponsables[0];
          let nameid=toResponsables[1];
          let idrole=toResponsables[2];
          let relation:string;
          console.log("type "+type+" nameId "+nameid+" idRole "+idrole);
          let topicId=this.caseId;
          if(idrole.includes( "e3b7a016-d331-4f89-a98d-91439a33ed14")){
            relation="2";
          }else{
             relation="3";
          }
          console.log("relation "+relation);
          //this.as.postResposableCases(type,nameid,topicId,idrole)
          this.as.postResposableCases(type,nameid,topicId,relation)
          .subscribe(response=>{
              console.log("data reposable set "+response);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data reposable set "+this.myArrStr);
                this.mySelec.close();
                this.getResposablesAdded(this.caseId,2);
              }else{
                alert("falla del sistema SECA, intente nuevamente");
                this.mySelec.close();
              }

          },error=>{

            this.handleError(error);
          }

          )

        }
        //this.onClose();

  }

  addContacToClient(caseId,personId){
    this.setResponsanbleToCase(this.responsables)

  }

  removeContacToClient(clientId,personId){
    this.as.RemoveResponsableToCase(clientId,personId)
          .subscribe(response=>{
              console.log("data Responsable set "+response.status.code+"  "+response.status.message);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data contac set "+this.myArrStr);
                this.mySelec.close();
                this.getResposablesAdded(this.caseId,2);

              }else{
                alert("falla del sistema SECA, intente nuevamente");
                this.mySelec.close();
              }

          },error=>{

            this.handleError(error);
          }

          )

  }

  getStatusData(){
    this.as.getStatusData().subscribe(resp=>{
      console.log("status "+resp)
      this.Datastatus=resp.content
    })
  }

  getCaseDetailData(){

    this.as.getCasesDetailData(this.caseId).subscribe(data=>{
      console.log("data "+data)
      this.myArrStr= JSON.stringify(data.content);
      console.log("response case detail "+this.myArrStr);
      console.log("data cases detail "+data.status.code+" message "+data.status.message);

      if(data.status.code=="200" && data.status.message=="Ok!"){


        this.expedient=data.content[0].expedient;
        console.log("expedient  "+this.expedient);
        this.caseId=data.content[0].id;
        this.createById=data.content[0].user_id;
        console.log("case id  "+this.caseId);
        this.CaseDetailData=data.content;
        this.created_at=data.content[0].created_at;
        this.status=data.content[0].status_name;
        this.status_id=data.content[0].status_id;
        this.title=data.content[0].title
        this.synopsis=data.content[0].synopsis;
        this.defendants=data.content[0].defendants;
        this.participants=data.content[0].participants;
        this.files=data.content[0].files;
        this.tracking=data.content[0].tracking;
        this.Court=data.content[0].court;

        // this.hasResponsables(this.status_id);
        // this.hasClientAndContact(this.status_id);

      }

    },
    error=>{
      console.log("Error get Detail cases"+error)
      this.handleError(error);
    }
    )

  }



  onClose() {

    this.dialogRef.close();
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 850) ? 1 : 2;
    this.breakpointbig = (window.innerWidth <= 1300) ? 3 : 4;
    console.log("breakpoint "+this.breakpoint)
    console.log("breakpointbig "+this.breakpointbig)
    if(this.breakpoint===1 && this.breakpointbig===3){
      this.dialogRef.updateSize('75%', '75%');
    }else if(this.breakpoint===2 && this.breakpointbig===3){
      this.dialogRef.updateSize('50%');
    }else{
      this.dialogRef.updateSize('45%');
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  getContactText(name,index){
    console.log(name+" "+this.arrayKyeContacs[index])
    console.log("Array contac "+this.contacs)
  }

  getStatusText(status,statusid){

    console.log("Status "+status+" id "+statusid);
    this.status=status;
    this.status_id=statusid;

  }

  getClientText(client,clientid,clienttype,clientreference){

   //console.log("Cliente "+client+" id "+clientid);
   this.clientId=clientid;
   this.clientType=clienttype;
   this.clientReference=clientreference;
   this.selectedClient=true;
   console.log("Cliente "+client+" id "+this.clientId
   +" type "+this.clientType+" reference "+this.clientReference);
   this.getClientDetailWithContact(this.clientId);

  }

  getClientDetailWithContact(clientId){
    this.as.getClientDetailData(clientId).subscribe(resp=>{
      console.log("Detail client data "+resp)
      this.myArrStr= JSON.stringify(resp.content.contacts);
      //this.DataContac=resp.content;
      console.log("Detail client data "+this.myArrStr)
      console.log("Detail client data "+Object.keys(resp.content.contacts))
      this.arrayKyeContacs=Object.keys(resp.content.contacts);
      console.log("arrayKyeContacs "+this.arrayKyeContacs);
      this.arrayNameContacs=[];
      for(var i = 0, len = this.arrayKyeContacs.length; i < len; i++)
         {
            this.arrayNameContacs.push(this.arrayKyeContacs[i]
              +"/"+resp.content.contacts[parseInt(this.arrayKyeContacs[i])]);
            //console.log("arrayNameContacs "+resp.content.contacts[parseInt(this.arrayKyeContacs[i])]);
         }
         console.log("arrayNameContacs "+this.arrayNameContacs);
         if(this.arrayNameContacs.length>0){
          this.showContact=false;
         }else{
          this.showContact=true;
          alert("Este cliente no tiene contactos");
         }
         console.log("arrayNameContacs def "+this.arrayNameContacs);

    })

  }

  onClean(){

        this.expedient='';
        this.caseId='';
        this.title=''
        this.synopsis='';
        this.defendants='';
        this.Court='';

  }

  hasResponsables(stausid){
    for(var i = 0, len = this.participants.length; i < len; i++)
        {
         if(this.participants[i].relation=="2"||this.participants[i].relation=="3"){
          console.log("entre ");
          this.resp=true;
          break;
         }

        }
  }

  hasClientAndContact(stausid){
    for(var i = 0, len = this.participants.length; i < len; i++)
        {
         if(this.participants[i].relation=="0"||this.participants[i].relation=="1"){
          console.log("tiene companias y contactos ");
          this.respClient=true;
          break;
         }

        }
  }



  onSummit(){

    if(typeof this.expedient !== 'undefined'
    &&typeof this.caseId !== 'undefined'
    &&typeof this.title !== 'undefined'
    &&typeof this.synopsis !== 'undefined'
    &&typeof this.status !== 'undefined'
    &&typeof this.defendants !== 'undefined'
    &&typeof this.Court !== 'undefined' ){

    this.upadateDataCases(this.title,this.expedient,this.status_id,this.synopsis,
             this.caseId,this.defendants,this.Court);
    }else{
      this.onClose();
    }
  }

    upadateDataCases(title,expedient,status_id,synopsis,caseId,defedant,Court){
      var dataCases =
      {
        "user_id": this.createById,
	      "status_id": status_id,
	      "expedient": expedient,
        "title": title,
        "defendants":defedant,
        "court": Court,
	      "synopsis": synopsis

      };
    //var jsonData = JSON.parse(data);
    console.log("Upadate obj "+dataCases);
    this.as.updateCaseItem(dataCases,caseId).subscribe(response=>{
      console.log("response "+response);
      this.myArrStr= JSON.stringify(response.status);
      console.log("response "+this.myArrStr);
      let myObj = JSON.parse(this.myArrStr);
      console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 && myObj.message==="Ok!"){
        // this.handleResultService(this.responsables,caseId);
         this.onClose();
      } error=>{
        console.log("Error delete case"+error)
        this.handleError(error);
      }
    });
    }



    // setResponsanbleToCase(responsables,caseId){

    //   for(var i = 0, len = responsables.length; i < len; i++)
    //       {
    //         console.log("Selected id "+this.responsables[i]);
    //         let toResponsables =  this.responsables[i].split("/");
    //         let nameid=toResponsables[0];
    //         let idrole=toResponsables[1];
    //         //let topicId="4";
    //         console.log("nameId "+nameid+" idRole "+idrole);
    //         this.as.postResposableCases(this.type,nameid,caseId,idrole).subscribe(response=>{
    //             console.log("data reposable set "+response);
    //             // this.myArrStr= JSON.stringify(response);
    //             // console.log("data reposable set "+this.myArrStr);

    //         },error=>{

    //           this.handleError(error);
    //         }

    //         )

    //       }
    //       this.onClose();

    // }

    setClientTocase(clientId,clientType,clientReference,caseId){
      this.as.postClientToCases(clientType,clientReference,caseId)
      .subscribe(resp=>{
        console.log("data client set to case "+resp.status.code);
        if(this.contacs.length>0){
            this.setContactClientToCase(this.contacs,caseId);
        }else{
          alert("Este cliente no tiene contactos")
          this.onClose();
        }

      },error=>{
        this.onClose();
        this.handleError(error);
      }

      )

    }

    setContactClientToCase(contacs,caseId){
      for(var i = 0, len = contacs.length; i < len; i++)
          {
            console.log("Selected contac id "+this.contacs[i]);
            let contacIdprov=this.contacs[i];
            this.as.postContacsToCases(contacIdprov,caseId)
            .subscribe(response=>{
            console.log("data reposable set "+response);
            },error=>{

              this.handleError(error);
            }

            )

          }
          this.onClose();

    }


    handleResultService(respon,caseId){
      if(!this.resp){

        if(!this.selectedResponsables){
          console.log("upadateDataCases resp no selected ")
          this.onClose();
        }else{
          console.log("upadateDataCasesWithResposables add resp add client and contac ")
          //this.setResponsanbleToCase(respon,caseId);
        }

     }else{

      console.log("upadateDataCases ")
      this.onClose()

      }

      if(!this.respClient){

        if(!this.selectedClient){
          console.log("upadateDataCases client no selected ")
          this.onClose();
        }else{
          console.log("upadateDataCasesWithClient add client and contac ")
          this.setClientTocase(this.clientId,this.clientType,this.clientReference,this.caseId)
        }

     }else{

      console.log("upadateDataCases ")
      this.onClose()

      }

    }


}

export class DataUpdateCaseModel {

  constructor(public id: string) {
  }
}

import { Component, OnInit } from '@angular/core';
import {MatDialogRef,MatDialog} from "@angular/material";
import { FormControl, FormGroup, Validators,FormBuilder,FormGroupDirective, NgForm } from '@angular/forms';
import {lawyer} from 'src/app/models/layer-class';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as sha512 from 'js-sha512';
import {DataApiService} from 'src/app/services/data-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmModalModel, ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  newPassValidations: FormGroup;
  confirmacionCLave:string;
  clave:string;
  resultCheck:boolean;
  errorMatcher = new CrossFieldErrorMatcher();
  unSub: any;
  //tosplit: string;
  provisional: string[];
  recovery: string;
  
  constructor(private authService: AuthService,private formBuilder: FormBuilder, private route: ActivatedRoute,private routing: Router, public dialog: MatDialog) { 
    this.ngOnInit();
  }

  ngOnInit() {
    //this.tosplit='password?recovery=62a60097b0caffc3ff71873446d20bf3b7d7793cedecac14cc19823748f7094d9722da930e3d2fd3b1220e2cb7b64c64b0c03c64feb2373bb2071560dfe3d280'

    this.makeUnsub();
     this.newPassValidations = this.formBuilder.group({
       password: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      passwordc: ['', [Validators.required,Validators.minLength(4), Validators.pattern('[a-zA-Z0-9-" "]+')]], 
      } , {validator: this.passwordValidator});  

  }

  makeUnsub(){
    /* this.unSub=this.route.params.subscribe(params=>{
      console.log("about param "+params.slug)
      console.log(params)
    }); */
    console.log("esta es la url: "+this.routing.url)
    //this.provisional= this.tosplit.split("=");
    this.provisional= this.routing.url.split("=")
    console.log(this.provisional[1])
    this.recovery=this.provisional[1]
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('passwordc').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }
 
   onPass(){

  
    var cifrada = sha512.sha512(this.clave);
    console.log(cifrada);
    var dataPass =
    {
    "recovery": this.recovery,
    "passphrase": cifrada,
    };
     this.authService.updatePassword(dataPass).subscribe(data=>{
      console.log(data.Status)
      if(data.status.code==200){
        const message = 'Su contraseña ha sido cambiada con Exito!!!';
        this.confirmDialog(message)
      }else{
        console.log('entre en el else')
        alert("falla del sistema, revise sus campos e intente nuevamente")
        const message = 'Ha ocurrido un error por favor intente más tarde';
        this.confirmDialog(message)
    }error=>{
      console.log("Error get Status"+error)
      this.handleError(error);
    }
    }); 

  } 

   private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
 
  confirmDialog(message): void {
    const dialogData = new ConfirmModalModel("Confirm Action", message,2);

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }

 /*  checkMath():boolean{

    if((this.clave!='' && this.confirmacionCLave!='')){
      if((this.clave==this.confirmacionCLave) ){
        this.resultCheck=false;
      }else{
        this.resultCheck=true;
      }

    }else{
      this.resultCheck=false;
    }

    console.log("Check "+this.resultCheck);
    return this.resultCheck

  } */

}




import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RestoreComponent } from './login/restore/restore.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MatMomentDateModule } from "@angular/material-moment-adapter";

import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardFooterComponent } from './dashboard/dashboard-footer/dashboard-footer.component';
import { DashboardTopMenuComponent } from './dashboard/dashboard-top-menu/dashboard-top-menu.component';
import { LoginComponent } from './login/login.component';
import { CalendarComponent } from './calendar/calendar.component';


// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { ButtonPaletteTopComponent } from './button-palette-top/button-palette-top.component';
import { CompanyCreateComponent } from './user/company-center/company-create/company-create.component';
import {MatDialogModule, MatIconModule, MatToolbarModule,
MatFormFieldModule, MatGridListModule,
 MatInputModule, MatSelectModule,MatCardModule,
 MatButtonModule,MatProgressBarModule } from "@angular/material";
import { LawyerCreateComponent } from './user/lawyercenter/lawyer-create/lawyer-create.component';
import { SubscriberCreateComponent } from './user/subscriber-center/subscriber-create/subscriber-create.component';
import { CalendarCreateComponent } from './calendar/calendar-create/calendar-create.component';
import { UserCreateComponent } from './user/user-center/user-create/user-create.component';

import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CaseCreateComponent } from './cases/casecenter/case-create/case-create.component';

import {Ng2TelInputModule} from 'ng2-tel-input';


//servicioss
import {DataApiService} from 'src/app/services/data-api.service';
import { CompanyUpdateComponent } from './user/company-center/company-update/company-update.component';
import { CaseUpdateComponent } from './cases/casecenter/case-update/case-update.component';
import { PasswordComponent } from './login/password/password.component';

import {DatePipe} from '@angular/common';
import { UserEditProfileComponent } from './user/user-profile/user-edit-profile/user-edit-profile.component';
import { LawyerUpdateComponent } from './user/lawyercenter/lawyer-update/lawyer-update.component';
import { UserUpdateComponent } from './user/user-center/user-update/user-update.component';


import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { ConfirmModalComponent } from './login/confirm-modal/confirm-modal.component';
@NgModule({

  declarations: [
    AppComponent,
    SideMenuComponent,
    DashboardComponent,
    DashboardFooterComponent,
    DashboardTopMenuComponent,
    LoginComponent,
    CalendarComponent,
    ButtonPaletteTopComponent,
    CompanyCreateComponent,
    LawyerCreateComponent,
    SubscriberCreateComponent,
    CalendarCreateComponent,
    ConfirmDialogComponent,
    UserCreateComponent,
    CaseCreateComponent,
    CompanyUpdateComponent,
    RestoreComponent,
    CaseUpdateComponent,
    PasswordComponent,
    UserEditProfileComponent,
    LawyerUpdateComponent,
    UserUpdateComponent,
    ConfirmModalComponent,





  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatButtonModule,
    MatCardModule,
    MatSelectInfiniteScrollModule,
    // ngx-translate and the loader module
     HttpClientModule,
     TranslateModule.forRoot({
         loader: {
             provide: TranslateLoader,
             useFactory: HttpLoaderFactory,
             deps: [HttpClient]
         }
     }),
     MatDialogModule,
     MatIconModule,
     MatToolbarModule,
     MatFormFieldModule,
     MatGridListModule,
     MatInputModule,
     MatButtonModule,
     ReactiveFormsModule,
     MatSelectModule,
     MatRadioModule,
     Ng2TelInputModule
     
  ],

  entryComponents: [
    CompanyCreateComponent,

    LawyerCreateComponent,
    SubscriberCreateComponent,
    LawyerUpdateComponent,
    ConfirmDialogComponent,
    ConfirmModalComponent,
    UserCreateComponent,
    CaseCreateComponent,
    CompanyUpdateComponent,
    CaseUpdateComponent,
    UserEditProfileComponent,
    UserUpdateComponent


  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { Component, OnInit, EventEmitter,Output} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { DataCompany } from 'src/app/models/data-company-interface';
import { Alert } from 'selenium-webdriver';
import {SharedService} from 'src/app/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {DataApiService} from 'src/app/services/data-api.service';

import { HttpClient,HttpHeaders,
  HttpErrorResponse,HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable, throwError } from 'rxjs';
import { DataPerson, DataPersonContent } from 'src/app/models/data-person-class';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.css']
})
export class CompanyCreateComponent implements OnInit {


  //rif = new FormControl('', [Validators.required]);

  verify:boolean;
  result:String;
  nameRif:string;
  Rif:String;
  CompanyName:String;
  TradeName:String;
  Phone:string;
  Address:String;
  //resumeData:DataCompany=new DataCompany();
  totalData:DataCompany[];
  toppings = new FormControl();
  contacs:Array<string>=[];
  DataContacs:DataPersonContent[];

  @Output() childEvent = new EventEmitter();
  searchKey: string;
  indexCount:number=0;
  newCompanyValidations: FormGroup;
  myArrStr:string;
  myArrStr1:string;
  idReferenceCompany:string;

  constructor(public dialogRef: MatDialogRef<CompanyCreateComponent>,
    private translate: TranslateService,private _sharedService:SharedService,
    private formBuilder: FormBuilder,public as:DataApiService) {
      translate.setDefaultLang('es');
  }


  ngOnInit() {
    this.newCompanyValidations = this.formBuilder.group({
      rif: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10), Validators.pattern('[a-zA-Z0-9-" "]+')]],
      company: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      tradeName: ['', [Validators.minLength(4), Validators.maxLength(50), Validators.pattern('[Ø-öø-ÿa-zA-Z0-9-" "/,.]+')]],
      phone: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(13), Validators.pattern('[0-9-" "-+]+')]],
      Address: ['', [Validators.required,Validators.minLength(4)]],
    });

    this.getContactData();

  }

  getContactData(){
    this.as.getPersonData().subscribe(data=>{
      // console.log("data "+data)

      if(data.status.code==200 && data.status.message=="Ok!"){
      this.DataContacs=data.content;
    }else{
      alert("falla del sistema SECA, intente nuevamente");
    }
    },
    error=>{
      console.log("Error get Company"+error)
      this.handleError(error);
    }
    )

  }

  onClose() {

    this.dialogRef.close();
  }

  onClean(){
    this.Rif='';
    this.CompanyName='';
    this.TradeName='';
    this.Phone='';
    this.Address='';
  }


  onSummit(){

    if(typeof this.Rif !== 'undefined'
    &&typeof this.CompanyName !== 'undefined'
    &&typeof this.TradeName !== 'undefined'
    &&typeof this.Address !== 'undefined' ){
    console.log("por aca se imprime "+this.indexCount);
    console.log("por aca se imprime "+this.Rif);
    console.log("por aca se imprime "+this.CompanyName);
    console.log("por aca se imprime "+this.TradeName);
    console.log("por aca se imprime "+this.Address);
    this.setCompanyService(this.Rif,this.CompanyName,
      this.TradeName,this.Phone,this.Address)

   this.onClose();
    }else{
      this.onClose();
    }
  }

  setCompanyService(Rif,CompanyName,TradeName,Phone,Address){
    // var dataCompany =
    //                  {
    //                   "business_id": Rif,
    //                   "business_name": CompanyName,
    //                   "trade_name": TradeName,
    //                   "address": Address,
    //                   "status_id": "10000000-0000-0000-0000-000000000000",
    //                   "email": "",
    //                   "phone": ""
    //                  };

  const current = new Date();
  const timestamp = current.getTime();
  console.log("timestamp "+timestamp)
  console.log("telefono "+this.Phone)
  var dataCompany =
                    {"company": {
                        "business_id": Rif,
                        "business_name": CompanyName,
                        "trade_name": TradeName,
                        "address": Address,
                        "status_id": "10000000-0000-0000-0000-000000000000",
                        "email": "empresa@fake-mail.com",
                        "phone": Phone
                      },
                     "account": "empresa_"+timestamp,
                     "role_id": "1a1c72bd-002d-4257-8b9f-bfc8d713ab73"
                    };

    console.log("Json data cases "+dataCompany);

    const obj = {
      Rif,
      CompanyName,
      TradeName,
      Phone,
      Address,

    };
    console.log("Objeto "+obj);

    this.as.createUserCompany(dataCompany).subscribe(response=>{
      console.log("response "+response);
      this.myArrStr= JSON.stringify(response.status);
      console.log("response "+this.myArrStr);
      let myObj = JSON.parse(this.myArrStr);
      console.log("JSON2  "+myObj.code+" "+myObj.message);
      if(myObj.code===200 && myObj.message==="Ok!"){
        console.log("REFERENCE COMPANY  "+response.content.id);
        console.log("Token COMPANY  "+response.content.token);
        this.idReferenceCompany=response.content.id;
        if(this.contacs.length>0){
          this.setContactToUserCompany(this.contacs,this.idReferenceCompany);
        }else{
          console.log("no selecciono contactos contactos  ");
          this.onClose();
        }

      } error=>{
        console.log("Error delete case"+error)
        this.onClose();
        this.handleError(error);
      }
    });

  }

  setContactToUserCompany(contacts,idreferencecompany){

    for(var i = 0, len = contacts.length; i < len; i++)
        {
          let idcontact=contacts[i];
          this.as.postContacsToCompanys(idreferencecompany,idcontact)
          .subscribe(response=>{
              console.log("data contact set "+response);
              if(response.status.code==200 && response.status.message=="Ok!"){
                this.myArrStr= JSON.stringify(response);
                console.log("data contact set "+this.myArrStr);
              }else{
                alert("falla del sistema SECA, intente nuevamente");
              }

          },error=>{
            console.log("error set contact company "+error);
            this.handleError(error);
          }

          )

        }
        this.onClose();

  }

  getContacs(name,lastname,index){
    console.log("La seleccion del contacto id es "+name+" "+lastname+" "+index)
    console.log("contactos "+this.contacs)

  }

  makenavigator(language){
    console.log("wasPushButtonTranslate 3 "+language);
    this._sharedService.messageSource.next(language);
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.makenavigator(language)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('ve');
  }
  getNumber(obj){
    console.log(obj);
    this.Phone = obj;
  }

}





import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from 'src/app/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
    title: string;
    message: string;
    indexSelected:number;
  constructor(private routerone: Router,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalModel,
    private translate: TranslateService,
    private _sharedService:SharedService) {
    // Update view with given values
    translate.setDefaultLang('es');
    this.title = data.title;
    this.message = data.message;
    this.indexSelected=data.index;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
    if(this.indexSelected==1){
      this.destroySession();
    }
    this.routerone.navigate(['/login']);

  }

  destroySession(){
    console.log("destroySession()")
    localStorage.clear();
  }
}

export class ConfirmModalModel {

  constructor(public title: string, public message: string,public index:number) {
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import {DataCompanys,DataCompanysContent} from 'src/app/models/data-company-class';
import { DataCases, DataCasesContent } from 'src/app/models/data-cases-class';
import { DataStatus, DataStatusContent } from 'src/app/models/data-status-class';
import { DataCategory, DataCategoryContent } from 'src/app/models/data-category-class';
import { DataClasesDetail, DataClasesDetailContent } from 'src/app/models/data-cases-detail-class';
import { DataResposables, DataResposablesContent } from 'src/app/models/data-responsables-class';
import { DataPerson, DataPersonContent } from 'src/app/models/data-person-class';
import { DataLawyers, DataLawyersContent } from 'src/app/models/data-lawyer-class';
import {DataSpecialties, DataSpecialtiesContent} from 'src/app/models/data-specialties-class'
import {DataCasesSet} from 'src/app/models/setdata-cases-class';
import {DataCompany} from 'src/app/models/data-company-interface';
import {AuthService} from 'src/app/services/auth.service';
import {UserInterface} from 'src/app/models/user-interface';
import {DataClient,DataClientContent} from 'src/app/models/data-client-class';
import {DataMessageRefreshDetail,DataMessageRefreshDetailContent} from 'src/app/models/data-refresmessage';
import {DataUserDetail,DataUserDetailContent} from 'src/app/models/data-user-detail';
import {DataRoleDetail,DataRoleDetailContent} from 'src/app/models/data-role-detail';
import {DataFilesCases,DataFilesCasesContent} from 'src/app/models/file-cases-data';
import {DataContacAddedDetail,DataContacAddedDetailContent} from 'src/app/models/data-contac-added';
import {DataContacAddedTopicDetail,DataContacAddedTopicDetailContent} from 'src/app/models/data-contac-addedtopic';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {

  //uriBase='http://seca-api.exiresoft.com.ve';
  //uriBase='http://fosterukke-001-site1.etempurl.com/';
  uriBase='http://api.appseca.website/';
  //uriBase='http://api-seca.exiresoft.com.ve';

  url:string;

  currentUser:UserInterface;
  currentToken:string;


  constructor(private http:HttpClient,public authservice:AuthService) {
    this.currentUser=this.authservice.getCurrentUser();
    this.currentToken=this.currentUser.token;

   }

  getCompanyData(rowsNum, pageNum):Observable<DataCompanysContent>{
    //this.url=this.uriBase+'/Company?sort=id desc';
   // this.url=this.uriBase+'/Company?sort=created_at desc & filter=status_id="10000000-0000-0000-0000-000000000000"';
   this.url=this.uriBase+'/Company?page_num='+pageNum+'&rows_num='+rowsNum+'& sort=created_at desc & filter=status_id="10000000-0000-0000-0000-000000000000"';
   //this.url=this.uriBase+'/Company?sort=created_at desc & filter=status_id="10000000-0000-0000-0000-000000000000" and type="companies"';
   console.log("Url Get Company "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataCompanysContent>(this.url,{headers})

  }

  getClientData():Observable<DataClientContent>{
    this.url=this.uriBase+'/Client?';
    console.log("Url Get Client "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataClientContent>(this.url,{headers})

  }

  getUsersClientData(rowsNum, pageNum):Observable<DataUserDetailContent>{
    //this.url=this.uriBase+'/User?filter=role_id=4';
    //this.url=this.uriBase+'/user?sort=created_at desc & filter=status_id="10000000-0000-0000-0000-000000000000" and role_id="1a1c72bd-002d-4257-8b9f-bfc8d713ab73"';
    this.url=this.uriBase+'/user?page_num='+pageNum+'&rows_num='+rowsNum+'& sort=created_at desc & filter=status_id="10000000-0000-0000-0000-000000000000" and role_id="1a1c72bd-002d-4257-8b9f-bfc8d713ab73"';
    console.log("Url Get Client "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataUserDetailContent>(this.url,{headers})

  }

  getClientDetailData(clientId):Observable<DataClientContent>{
    this.url=this.uriBase+'/Client/'+clientId;
    console.log("Url Get Client "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

  return this.http.get<DataClientContent>(this.url,{headers})

  }

  getCasesData(rowsNum, pageNum,roleId,userId,type,reference):Observable<DataCasesContent>{
    //this.url=this.uriBase+'/Topic?rows_num='+row+'&filter=status_id!=2&sort=id desc&page_num='+pagenumber;
    // this.url=this.uriBase+'/Topic?filter=status_id!=2&sort=id desc&page_num='+pagenumber;
   // this.url=this.uriBase+'/Topic';
   //this.url=this.uriBase+'/topic?filter=status_id<>"20000000-0000-0000-0000-000000000000" & sort=created_at desc &page_num='+pagenumber;
   if(roleId.includes('10000000-0000-0000-0000-000000000000')){
    this.url=this.uriBase+'/topic?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=status_id<>"20000000-0000-0000-0000-000000000000" & sort=created_at desc ';
    console.log("si eres administrador "+userId)
  }else{

    //this.url=this.uriBase+'/topic?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=user_id="'+userId+'" and status_id<>"20000000-0000-0000-0000-000000000000" & sort=created_at desc ';
    //this.url=this.uriBase+'/topic?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=Find_In_Set("'+userId+'",users_involved) > 0 and status_id<>"20000000-0000-0000-0000-000000000000" & sort=created_at desc ';
    console.log("ya no eres administrador " +reference+" type "+type)
    this.url=this.uriBase+'/topic?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=Find_In_Set("'+type+'|'+reference+'",involved) > 0 and status_id<>"20000000-0000-0000-0000-000000000000" & sort=created_at desc ';

   }

    console.log("Url Get Cases "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataCasesContent>(this.url,{headers})

  }

  getCasesFilteredData(wordKey:string):Observable<DataCasesContent>{
    this.url=this.uriBase+'/Topic?filter=status_id<>"20000000-0000-0000-0000-000000000000" and (DATE_FORMAT(created_at, "%d/%c/%Y") REGEXP \'.*'+wordKey+'.*\' or category_name regexp \'.*'+wordKey+'.*\' or status_name regexp \'.*'+wordKey+'.*\' or user_full_name regexp \'.*'+wordKey+'.*\')';
    console.log("Url Get Company "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataCasesContent>(this.url,{headers})

  }

  getCompanyFilteredData(wordKey:string):Observable<DataCompanysContent>{
    // this.url=this.uriBase+'/Company?filter= business_id regexp \'.*'+wordKey+'.*\' or business_name regexp \'.*'+wordKey+'.*\' or trade_name regexp \'.*'+wordKey+'.*\'';
    this.url=this.uriBase+'/Company?filter=status_id="10000000-0000-0000-0000-000000000000" and (business_id regexp \'.*'+wordKey+'.*\' or business_name regexp \'.*'+wordKey+'.*\' or trade_name regexp \'.*'+wordKey+'.*\')';
    console.log("Url Get Company "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataCompanysContent>(this.url,{headers})

  }

  getStatusData():Observable<DataStatusContent>{
    this.url=this.uriBase+'/Status';
    console.log("Url Get Status "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataStatusContent>(this.url,{headers})

  }

  getCategoryData():Observable<DataCategoryContent>{
    this.url=this.uriBase+'/Category?';
    console.log("Url Get Category "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataCategoryContent>(this.url,{headers})

  }

  getCasesDetailData(caseId):Observable<DataClasesDetailContent>{
    //this.url=this.uriBase+'/Topic/'+caseId;
    this.url=this.uriBase+'/topic?filter=id="'+caseId+'"';
    console.log("Url Get Case detail "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataClasesDetailContent>(this.url,{headers})

  }

  getPersonDetailData(personReference):Observable<DataPersonContent>{
    //this.url=this.uriBase+'/Person/'+personReference;
    this.url=this.uriBase+'/Person?filter=id="'+personReference+'"';
    console.log("Url Get Person detail "+this.url)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token':this.currentToken
    });

    return this.http.get<DataPersonContent>(this.url,{headers})

  }

  // setCaseData(user_id,status_id,category_id,casefile,title,synopsis):Observable<any>{
    setCaseData(dataBody:any):Observable<any>{
    this.url=this.uriBase+'/Topic';
    console.log("Url Set Cases "+this.url)
    console.log("Body Cases "+dataBody);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'token':this.currentToken
    });

    return this.http.post(this.url,dataBody,{headers})

  }

  createCompany(dataBody:any):Observable<any>{
    this.url=this.uriBase+'/Company';
    console.log("Url Set Companys "+this.url)
    console.log("Body companys "+dataBody);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'token':this.currentToken
    });

    return this.http.post(this.url,dataBody,{headers})

  }

  // setCaseData(user_id,status_id,category_id,casefile,title,synopsis):Observable<any>{
  postNewMessage(caseId,sendMessage,user_id,valueType,valueEmail):Observable<any>{
      this.url=this.uriBase+'/TopicTracking';
      console.log("Url Set message "+this.url)

      // var formData: any = new FormData();
      // formData.append('topic_id', caseId);
      // formData.append('message', sendMessage);
      // formData.append('user_id', user_id);
      // formData.append('visibility', valueType);

      var dataMessage =
      {
        "topic_id": caseId,
        "message": sendMessage,
        "user_id": user_id,
        "visibility": valueType,
        "mail_sended": valueEmail

      };

      console.log("Json data cases "+dataMessage);

      const obj = {
        caseId,
        sendMessage,
        user_id,
        valueType,
        valueEmail

      };
      console.log(obj);

      //console.log("Body message "+formData.get('user_id'));
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataMessage,{headers})

    }

    deleteCasesItem(caseId):Observable<any>{
      this.url=this.uriBase+'/Topic/'+caseId;
      console.log("Url Delete Case "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})

    }

    deleteCompanyItem(companyId):Observable<any>{
      this.url=this.uriBase+'/Company/'+companyId;
      console.log("Url Delete Company "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})
      //return this.http.put(this.url,obj,{headers})

    }

    updateCompanyItem(obj,companyId):Observable<any>{
      this.url=this.uriBase+'/Company/'+companyId;
      console.log("Url Update Company "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.put(this.url,obj,{headers})

    }

    updateCaseItem(obj,caseId):Observable<any>{
      this.url=this.uriBase+'/Topic/'+caseId;
      console.log("Url Update Cases "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.put(this.url,obj,{headers})

    }

    deleteCaseItem(caseId):Observable<any>{
      this.url=this.uriBase+'/Topic/'+caseId;
      console.log("Url Update Cases "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})

    }

    getCompanyDetailData(caseId:string):Observable<DataCompanysContent>{
      //this.url=this.uriBase+'/Company/'+caseId;
      this.url=this.uriBase+'/Company?filter=id="'+caseId+'"and status_id=10000000-0000-0000-0000-000000000000';
      console.log("Url Get Company detail "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataCompanysContent>(this.url,{headers})

    }

    getResponsablesData(rowsNum, pageNum):Observable<DataResposablesContent>{
      //this.url=this.uriBase+'/User?filter=role_id = 3 or role_id= 2';
      //this.url=this.uriBase+'/user?filter=status_id="10000000-0000-0000-0000-000000000000" and (role_id="e3b7a016-d331-4f89-a98d-91439a33ed14" or role_id="020226d9-2662-4143-b0d1-30ec96226bd1") ';
      this.url=this.uriBase+'/user?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=status_id="10000000-0000-0000-0000-000000000000" and (role_id="e3b7a016-d331-4f89-a98d-91439a33ed14" or role_id="020226d9-2662-4143-b0d1-30ec96226bd1") & sort=created_at desc ';
      console.log("Url Get user responsable "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataResposablesContent>(this.url,{headers})

    }

   // uploadFileData(dataBody:any):Observable<any>{
      uploadFileData(caseId:string,commentAdd:string,fileAdd):Observable<any>{
      this.url=this.uriBase+'/topic/upload_file?token='+this.currentToken;
      console.log("Url upload files "+this.url);
      console.log("upload files id "+caseId);
      console.log("upload files comment "+commentAdd);
      console.log("upload files  "+fileAdd.name+" "+fileAdd.type+" "+fileAdd.size);
      const formData = new FormData();
      formData.append('topic_id', caseId);
      formData.append('comment', commentAdd);
      formData.append('file', fileAdd);

      // const headers = new HttpHeaders({
      //   'Content-Type': 'multipart/form-data',
      //   'Accept': 'application/json',
      //   'token':this.currentToken,

      // });

      // return this.http.post<any>(this.url,formData,{headers,
      //   reportProgress: true,
      //   observe: 'events'})

        return this.http.post<any>(this.url,formData,{
          reportProgress: true,
          observe: 'events'})

    }

    postResposableCases(type,reference,topic_id,relation):Observable<any>{
      this.url=this.uriBase+'/TopicParticipant';
      console.log("Url Set message "+this.url)
      var dataResposables =
      {
        "type": type,
        "reference": reference,
        "topic_id": topic_id,
        "relation": relation,

      };

      console.log("Json data cases "+dataResposables);


      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataResposables,{headers})

    }

    postClientToCases(type,reference,topic_id):Observable<any>{
      this.url=this.uriBase+'/TopicParticipant';
      console.log("Url Set message "+this.url)
      var dataClients =
      {
        "type": type,
        "reference": reference,
        "topic_id": topic_id,
        "relation": "1",

      };

      console.log("Json data cases "+dataClients);


      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataClients,{headers})

    }

    postContacsToCases(reference,topic_id):Observable<any>{
      this.url=this.uriBase+'/TopicParticipant';
      console.log("Url Set message "+this.url)
      var dataContacs =
      {
        "type": "people",
        "reference": reference,
        "topic_id": topic_id,
        "relation": "0",

      };

      console.log("Json data cases "+dataContacs);


      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataContacs,{headers})

    }



    getLawyersData(rowsNum, pageNum): Observable<DataLawyersContent>{

    this.url=this.uriBase+'/user?filter=role_id="e3b7a016-d331-4f89-a98d-91439a33ed14" and status_name = "Disponible" or role_id="020226d9-2662-4143-b0d1-30ec96226bd1" and status_name = "Disponible" & rows_num='+ rowsNum +'& page_num='+ pageNum + '& sort=created_at desc';

      console.log("Url Get Lawyers "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataLawyersContent>(this.url,{headers})
    }

    getSpecialtiesData(): Observable <DataSpecialtiesContent>{
      this.url=this.uriBase+'/Specialty?filter=status_name= "Disponible"';
      console.log("Url Get Specialties "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataSpecialtiesContent>(this.url,{headers})
    }

    setNewLawyers(data: any):Observable<any>{
      this.url=this.uriBase+'/user/signup?';
      console.log("Url Set Cases "+this.url)
      console.log("Body "+data);
      console.log(this.currentToken)
/*       const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });
      return this.http.post(this.url,data,{headers}) */
      return this.http.post(this.url,data)
    }

    setNewUsers(data: any):Observable<any>{
      this.url=this.uriBase+'/user/signup?';
      console.log("Url Set Cases "+this.url)
      console.log("Body "+data);
      console.log(this.currentToken)
/*        const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      }); */
      //return this.http.post(this.url,data,{headers})
      return this.http.post(this.url,data)
    }

    deleteLawyerItem(lawyerId):Observable<any>{
      this.url=this.uriBase+'/user/'+lawyerId;
      console.log("Url Delete Company "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})
      //return this.http.put(this.url,obj,{headers})

    }

    deleteUserItem(userId):Observable<any>{
      this.url=this.uriBase+'/user/'+userId;
      console.log("Url Delete Company "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})
      //return this.http.put(this.url,obj,{headers})

    }

    getLastMessageData(lastmesssageID,caseId): Observable<DataMessageRefreshDetailContent>{
      //this.url=this.uriBase+'/TopicTracking?filter=id> "'+lastmesssageID+'" and topic_id="'+caseId+'" & sort=id asc';
      this.url=this.uriBase+'/TopicTracking?filter=id>= "'+lastmesssageID+'" and topic_id="'+caseId+'"';
      console.log("Url Get message "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataMessageRefreshDetailContent>(this.url,{headers})
    }

    getMessageCaseData(caseId): Observable<DataMessageRefreshDetailContent>{
      //this.url=this.uriBase+'/TopicTracking?filter=topic_id="'+caseId+'" & sort=id desc';
      this.url=this.uriBase+'/TopicTracking?filter=topic_id="'+caseId+'" ';
      console.log("Url Get message "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataMessageRefreshDetailContent>(this.url,{headers})
    }

    getUserDetailData(userId:string,ident:number): Observable<DataUserDetailContent>{
      //this.url=this.uriBase+'/User/'+userId;
      console.log(userId)
      if(ident==1){
      this.url=this.uriBase+'/person?filter=id="'+userId+'"';
      }else{
       this.url=this.uriBase+'/user?filter=id="'+userId+'"';
      }


      console.log("Url Get message "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataUserDetailContent>(this.url,{headers})
    }

    deletefile(fileId):Observable<any>{
      //this.url=this.uriBase+'/TopicFile/'+fileId;
      this.url=this.uriBase+'/TopicFile/'+fileId+'? permanently';
      console.log("Url Delete File "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})

    }

    getUsersData(rowsNum, pageNum): Observable<DataLawyersContent>{
      //this.url=this.uriBase+'/User?filter=role_id = 4 & sort=id desc';
      //this.url=this.uriBase+'/user?filter=role_id="1a1c72bd-002d-4257-8b9f-bfc8d713ab73" and status_name = "Disponible" and type ="people"';
      this.url=this.uriBase+'/user?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=role_id="1a1c72bd-002d-4257-8b9f-bfc8d713ab73" and status_name = "Disponible" and type ="people"& sort=created_at desc';
      console.log("Url Get Users "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataLawyersContent>(this.url,{headers})
    }

    getRolIdDetailData(rolId): Observable<DataRoleDetailContent>{
      this.url=this.uriBase+'/Role/'+rolId;
      console.log("Url Get rol id name "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.get<DataRoleDetailContent>(this.url,{headers})
    }

    updateProfileData(obj,userId):Observable<any>{
      //this.url=this.uriBase+'/User/'+userId;
      this.url=this.uriBase+'/Person/'+userId;
      console.log("Url Update User profile "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.put(this.url,obj,{headers})

    }

    deleteMessage(MessageId):Observable<any>{
      //this.url=this.uriBase+'/TopicTracking/'+MessageId;
      this.url=this.uriBase+'/TopicTracking/'+MessageId+'? permanently';
      console.log("Url Delete message "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.delete(this.url,{headers})

    }

    postSpecialtyGrantUser(data):Observable<any>{
      this.url=this.uriBase+'/Specialty/grant_user';
       const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });
      return this.http.post(this.url,data,{headers})
    }

    getUserFilteredData(wordKey:string):Observable<DataCompanysContent>{
      this.url=this.uriBase+'/user?filter= full_name regexp \'.*'+wordKey+'.*\' or account regexp \'.*'+wordKey+'.*\' or identification regexp \'.*'+wordKey+'.*\' or phone regexp \'.*'+wordKey+'.*\' or email regexp \'.*'+wordKey+'.*\'';
      console.log("Url Get user "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataCompanysContent>(this.url,{headers})

    }

    getLawyerFilteredData(wordKey:string):Observable<DataCompanysContent>{
      this.url=this.uriBase+'/user?filter= full_name regexp \'.*'+wordKey+'.*\' or account regexp \'.*'+wordKey+'.*\'  or phone regexp \'.*'+wordKey+'.*\' or email regexp \'.*'+wordKey+'.*\' or specialties regexp \'.*'+wordKey+'.*\' or rol_name regexp \'.*'+wordKey+'.*\'';
      console.log("Url Get user "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataCompanysContent>(this.url,{headers})

    }

    getFilesCasesData(caseId):Observable<DataFilesCasesContent>{
      //this.url=this.uriBase+'/Category?';
      this.url=this.uriBase+'/TopicFile?filter=topic_id="'+caseId+'"';
      console.log("Url Get files cases "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataFilesCasesContent>(this.url,{headers})

    }

    getPersonData():Observable<DataPersonContent>{
      //this.url=this.uriBase+'/Person/'+personReference;
    this.url=this.uriBase+'/Person?filter=status_id="10000000-0000-0000-0000-000000000000" and (role_id is null or role_id = "1a1c72bd-002d-4257-8b9f-bfc8d713ab73")';
    console.log("Url Get Person person "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataPersonContent>(this.url,{headers})

    }

    createUserCompany(dataBody:any):Observable<any>{
      //this.url=this.uriBase+'/Company';
      this.url=this.uriBase+'/user/signup';
      console.log("Url Set Companys "+this.url)
      console.log("Body companys "+dataBody);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post(this.url,dataBody,{headers})

    }

    postContacsToCompanys(userid,personid):Observable<any>{
      this.url=this.uriBase+'/user/link_contact';
      console.log("Url Set message "+this.url)
      var dataContacs =
      {
        "user_id": userid,
        "person_id": personid
      };

      console.log("Json data cases "+dataContacs);


      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataContacs,{headers})

    }

    getcomanyIdfromUserService(index):Observable<DataUserDetailContent>{
      //this.url=this.uriBase+'/Person/'+personReference;
    this.url=this.uriBase+'/user?filter=type="companies" and reference= "'+index+'" and status_id="10000000-0000-0000-0000-000000000000"';
    console.log("Url Get comany user id "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataUserDetailContent>(this.url,{headers})

    }

    getContacsAdded(index):Observable<DataContacAddedDetailContent>{
      //this.url=this.uriBase+'/Person/'+personReference;
    this.url=this.uriBase+'/user/contacts?filter=user_id= "'+index+'"';
    console.log("Url Get Contact of cclient company "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataContacAddedDetailContent>(this.url,{headers})

    }

    AddContacsToClient(clientId,personid):Observable<any>{
      this.url=this.uriBase+'/user/link_contact';
      console.log("Url Add contac "+this.url)
      var dataContacs =
      {
        "user_id": clientId,
        "person_id": personid
      };

      console.log("Json data cases "+dataContacs);


      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'token':this.currentToken
      });

      return this.http.post<any>(this.url,dataContacs,{headers})


    }

    RemoveContacsToClient(clientId,personid):Observable<any>{
      this.url=this.uriBase+'/user/unlink_contact';
      console.log("Url Remove Contacs "+this.url)
      // var dataContacs =
      // {
      //   "user_id": clientId,
      //   "person_id": personid
      // };

      // console.log("Json data cases "+dataContacs);
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token':this.currentToken
        }),
        body: {
          "user_id": clientId,
          "person_id": personid
        }
      }


      //return this.http.delete<any>(this.url,dataContacs,{headers})
      return this.http.delete<any>(this.url,options)


    }

    RemoveResponsableToCase(caseId,personid):Observable<any>{
      this.url=this.uriBase+'/TopicParticipant';
      console.log("Url Remove Responsables "+this.url)

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token':this.currentToken
        }),
        body: {
            "topic_id": caseId,
            "type": "people",
            "reference": personid
        }
      }


      //return this.http.delete<any>(this.url,dataContacs,{headers})
      return this.http.delete<any>(this.url,options)


    }

    getContacsInTopicAdded(topicId):Observable<DataContacAddedTopicDetailContent>{
      //this.url=this.uriBase+'/Person/'+personReference;
    this.url=this.uriBase+'/TopicParticipant?filter=topic_id="'+topicId+'" and relation="0"';
    console.log("Url Get Contact of client  "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataContacAddedTopicDetailContent>(this.url,{headers})

    }

    getResponsablesInTopicData(topicId):Observable<DataResposablesContent>{
      //this.url=this.uriBase+'/User?filter=role_id = 3 or role_id= 2';
      this.url=this.uriBase+'/TopicParticipant?filter=topic_id="'+topicId+'" and (relation="2" or relation="3")  ';
      //this.url=this.uriBase+'/user?page_num='+pageNum+'&rows_num='+rowsNum+'& filter=status_id="10000000-0000-0000-0000-000000000000" and (role_id="e3b7a016-d331-4f89-a98d-91439a33ed14" or role_id="020226d9-2662-4143-b0d1-30ec96226bd1") & sort=created_at desc ';
      console.log("Url Get responsable added "+this.url)
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token':this.currentToken
      });

      return this.http.get<DataResposablesContent>(this.url,{headers})

    }

}
